import React, {useState} from 'react';
import {Header, Icon, Menu, Segment} from 'semantic-ui-react';
import {useNavigate} from 'react-router-dom';
import {CandidateInfo} from './CandidateInfo';
import {CandidateTestSchedule} from './CandidateTestSchedule';

enum MenuOptions {
  HOME,
  SCHEDULE
}

export const CandidateMenu = (): JSX.Element => {
  const navigate = useNavigate();
  const [activeMenu, setActiveMenu] = useState<MenuOptions>(MenuOptions.SCHEDULE);

  return (
    <div style={{
      paddingTop: '1%',
      paddingBottom: '2%',
      paddingLeft: '15%',
      paddingRight: '15%',
      background: 'lightgrey',
      minHeight: '100vh'
    }}>
      <Segment padded='very' raised>
        <Menu secondary pointing>
          <Menu.Item
            name='Home'
            active={activeMenu == MenuOptions.HOME}
            onClick={() => {setActiveMenu(MenuOptions.HOME)}}
          />
          <Menu.Item
            name='Test Slots'
            active={activeMenu == MenuOptions.SCHEDULE}
            onClick={() => {setActiveMenu(MenuOptions.SCHEDULE)}}
          />
          <Menu.Menu position='right'>
            <Menu.Item
              name='logout'
              onClick={() => {
                localStorage.removeItem('sessionId');
                navigate('/');
              }}
            />
          </Menu.Menu>
        </Menu>
        <Header as='h2' icon textAlign='center' block>
          <Icon name='users' circular/>
          <Header.Content>Birbal AI</Header.Content>
          <Header.Subheader>Research and development lab for ML/AI</Header.Subheader>
        </Header>
        {activeMenu == MenuOptions.HOME ? <CandidateInfo/> : <></>}
        {activeMenu == MenuOptions.SCHEDULE ? <CandidateTestSchedule/> : <></>}
      </Segment>
    </div>
  )
};
