export let API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '';
if (API_BASE_URL.endsWith('/')) {
  API_BASE_URL = API_BASE_URL.slice(0, -1);
}

interface ResponseObject {
  errorMsg: string,
  data: any,
  statusCode: number
}

export async function makePostRequest(url: string, data: any = null, responseAsText = false) {
  const res: ResponseObject = {
    errorMsg: '',
    data: {},
    statusCode: -1
  };

  try {
    const response = await fetch(`${API_BASE_URL}${url}`, {
      method: 'POST',
      body: data,
      headers: {
        'Content-Type': `application/${data instanceof Uint8Array ? 'x-protobuf' : 'json'}`,
        'X-Session-Id': localStorage.getItem('sessionId') || ''
      }
    });

    if(response.status == 401) {
      console.error(`Unauthenticated used. Logging out. Response: ${response}`);
      localStorage.removeItem('sessionId');
      window.location.assign(`/`);
    }
    res.statusCode = response.status;
    if (responseAsText) {
      res.data = await response.text();
    } else {
      const blob = await response.blob();
      res.data = await blob.arrayBuffer();
    }
  } catch (error: any) {
    console.error('Call to careers server api failed. Reason : ', error.message);
    res.errorMsg = error.message;
  }

  return res;
}