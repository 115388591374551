import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {Button, Checkbox, Header, Table} from 'semantic-ui-react';
import {proto} from '../services/source/compiled';
import moment from 'moment';
import {makePostRequest} from '../util/api';

const UpcomingTestSlots = (props: { toBeBookedTestEnum: proto.Enum_dlTestName.E, setToBeBookedTestEnum: Dispatch<SetStateAction<proto.Enum_dlTestName.E | undefined>> }): JSX.Element => {
  const [selectedSlotId, setSelectedSlotId] = useState(-1);
  const [testSlots, setTestSlots] = useState<proto.IDlTestSlot[]>([]);
  const [createSlotApiTriggered, setCreateSlotApiTriggered] = useState(false);

  useEffect(() => {
    const fetchUpcomingSlots = async () => {
      const response = await makePostRequest('/public/candidate/getUpcomingTestSlots');
      const slotList = proto.PublicCandidateGetUpcomingTestSlotsResp.decode(new Uint8Array(response.data));
      setTestSlots(slotList.dlTestSlotList);
    }
    fetchUpcomingSlots();
  }, []);

  return (
    <>
      {testSlots.length == 0 ? <Header content={'No slots available. Check after sometime!'}/> :
        <Table striped celled color={'grey'} textAlign={'center'}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell colSpan='3'>
                Please Book a Test Slot!!
              </Table.HeaderCell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell>
                Slot Time
              </Table.HeaderCell>
              <Table.HeaderCell>
                Duration
              </Table.HeaderCell>
              <Table.HeaderCell>
                Choose Slot
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {testSlots.map(s => {
              return (
                <Table.Row key={s.id} active={s.id === selectedSlotId}>
                  <Table.Cell>{moment(s.startTime).format("dddd, MMMM Do YYYY, h:mm a")}</Table.Cell>
                  <Table.Cell>{(s.durationInMinutes || 0) / 60}hrs</Table.Cell>
                  <Table.Cell>
                    <Checkbox
                      radio
                      name='checkboxRadioGroup'
                      value={s.id?.toString()}
                      checked={s.id === selectedSlotId}
                      // @ts-ignore
                      onChange={(e, data) => setSelectedSlotId(parseInt(data.value || ''))}
                    />
                  </Table.Cell>
                </Table.Row>
              )
            })
            }
          </Table.Body>
        </Table>}
      {selectedSlotId == -1 ?
        <></>
        :
        <Button fluid basic positive icon={'checkmark'}
                disabled={createSlotApiTriggered}
                onClick={async () => {
                  setCreateSlotApiTriggered(true);
                  const request = new proto.PublicCandidateBookTestSlotReq();
                  request.testSlotId = selectedSlotId;
                  request.testName = props.toBeBookedTestEnum;
                  const encodedRequestBody = proto.PublicCandidateBookTestSlotReq.encode(request).finish();
                  const response = await makePostRequest('/public/candidate/bookTestSlot', encodedRequestBody);
                  setCreateSlotApiTriggered(false);
                  setSelectedSlotId(-1);
                  if (response.statusCode == 200) {
                    props.setToBeBookedTestEnum(undefined);
                  } else {
                    alert('Booking failed. Please try after sometime.');
                  }
                }}
                content={createSlotApiTriggered ? 'Booking slot. Please wait...' : 'Confirm Slot'}/>
      }
    </>
  );
}

export const CandidateTestSchedule = (): JSX.Element => {
  const [testStatusMsg, setTestStatusMsg] = useState('');
  const [toBeBookedTestEnum, setToBeBookedTestEnum] = useState<proto.Enum_dlTestName.E>();
  const [selectedSlots, setSelectedSlots] = useState<proto.PublicCandidateGetSelectedSlotListResp.ISelectedSlotDetails[]>([]);

  useEffect(() => {
    const checkTestStatus = async () => {
      const resp = await makePostRequest('/public/candidate/getTestStatus');
      const testStatus = proto.PublicCandidateGetTestStatusResp.decode(new Uint8Array(resp.data)).dlTestStatus;
      if (testStatus == proto.Enum_dlTestStatus.E.FILE2_SIX_MIN_PASSED) {
        setTestStatusMsg('Congratulations, you have passed both the tests.');
      } else if (testStatus == proto.Enum_dlTestStatus.E.FILE2_SIX_MIN_FAILED) {
        setTestStatusMsg('Sorry, you have failed TEST LEVEL 2.');
      } else if (testStatus == proto.Enum_dlTestStatus.E.FILE2_SIX_MIN_SUBMITTED) {
        setTestStatusMsg('You have completed TEST LEVEL 2. Please wait for results.');
      } else if (testStatus == proto.Enum_dlTestStatus.E.FILE2_SIX_MIN_SCHEDULED) {
        setTestStatusMsg('Current Status: LEVEL 2 scheduled!!');
      } else if (testStatus == proto.Enum_dlTestStatus.E.FILE1_TWO_MIN_SCHEDULED) {
        setTestStatusMsg('Current Status: LEVEL 1 scheduled!!');
      } else if (testStatus == proto.Enum_dlTestStatus.E.FILE1_TWO_MIN_PASSED) {
        setTestStatusMsg('Congratulations, you have passed TEST LEVEL 1. Please book slot for TEST LEVEL 2.');
        setToBeBookedTestEnum(proto.Enum_dlTestName.E.FILE2_SIX_MIN);
      } else if (testStatus == proto.Enum_dlTestStatus.E.FILE1_TWO_MIN_FAILED) {
        setTestStatusMsg('Sorry, you have failed TEST LEVEL 1.');
      } else if (testStatus == proto.Enum_dlTestStatus.E.FILE1_TWO_MIN_SUBMITTED) {
        setTestStatusMsg('You have completed TEST LEVEL 1. Please wait for results.');
      } else if (testStatus == proto.Enum_dlTestStatus.E.NO_TEST_SCHEDULED) {
        setTestStatusMsg('Book Test Slot for LEVEL 1');
        setToBeBookedTestEnum(proto.Enum_dlTestName.E.FILE1_TWO_MIN);
      } else {
        console.error('Something unexpected occurred!!!');
        alert('Something unexpected occurred!!!')
      }
    }
    checkTestStatus();

    const fetchBookedSlots = async () => {
      const response = await makePostRequest('/public/candidate/getSelectedSlotList');
      const slotList = proto.PublicCandidateGetSelectedSlotListResp.decode(new Uint8Array(response.data)).selectedSlotList;
      setSelectedSlots(slotList);
    }

    fetchBookedSlots();
  }, [toBeBookedTestEnum]);

  return (
    <>
      <Header color={'red'} textAlign={'center'} block>{testStatusMsg}</Header>
      {toBeBookedTestEnum == undefined ? <></> :
        <UpcomingTestSlots toBeBookedTestEnum={toBeBookedTestEnum} setToBeBookedTestEnum={setToBeBookedTestEnum}/>}
      {selectedSlots.length == 0 ? <></> :
        <Table striped celled color={'grey'} textAlign={'center'}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell colSpan={'10'}>
                Booked Slots Status
              </Table.HeaderCell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell>
                Slot Time
              </Table.HeaderCell>
              <Table.HeaderCell>
                Duration
              </Table.HeaderCell>
              <Table.HeaderCell>
                Test Level
              </Table.HeaderCell>
              <Table.HeaderCell>
                Status
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {selectedSlots.map(s => {
              let testStatus = <></>;
              let disabled = false;
              const status = proto.PublicCandidateGetSelectedSlotListResp.SelectedSlotDetails.SlotStatus;
              if (s.slotStatus == status.FAILED) {
                testStatus = <>Failed</>;
              } else if (s.slotStatus == status.PASSED) {
                testStatus = <>Passed</>;
              } else if (s.slotStatus == status.SUBMITTED) {
                testStatus = <>Completed. Yet to generate results.</>;
              } else if (s.slotStatus == status.CANCELLED) {
                testStatus = <>Slot cancelled</>;
                disabled = true;
              } else if (s.slotStatus == status.MISSED) {
                testStatus = <>Slot missed.</>;
                disabled = true;
              } else if (s.slotStatus == status.SCHEDULED_TODAY) {
                testStatus = <Button onClick={() => {
                  window.open('https://office-interview.birbal-ai.com/', '_blank')?.focus();
                }}>
                  Scheduled today. Click to open Test Dashboard!
                </Button>;
              } else if (s.slotStatus == status.UPCOMING) {
                testStatus = <>Test Scheduled.</>;
              } else {
                console.error(`Slot status not found. S: ${s}. Slots: ${selectedSlots}`);
                alert('Some unexpected error occurred.');
              }
              return (
                <Table.Row key={s.id} disabled={disabled}>
                  <Table.Cell>{moment(s.startTime).format("dddd, MMMM Do YYYY, h:mm a")}</Table.Cell>
                  <Table.Cell>{(s.durationInMinutes || 0) / 60}hrs</Table.Cell>
                  <Table.Cell>
                    {s.testName == proto.Enum_dlTestName.E.FILE2_SIX_MIN ? 'Level 2' : ''}
                    {s.testName == proto.Enum_dlTestName.E.FILE1_TWO_MIN ? 'Level 1' : ''}
                  </Table.Cell>
                  <Table.Cell>
                    {testStatus}
                  </Table.Cell>
                </Table.Row>
              )
            })}
          </Table.Body>
        </Table>
      }
    </>
  )
};
