import React, {useEffect, useState} from 'react';
import {Table} from 'semantic-ui-react';
import {proto} from '../services/source/compiled';
import {makePostRequest} from "../util/api";

export const CandidateInfo = (): JSX.Element => {
  const [candidate, setCandidate] = useState<proto.ICandidate>();

  useEffect(() => {
    const fetchCandidateDetails = async () => {
      const response = await makePostRequest('/public/candidate/getDetails');
      const resp = proto.PublicCandidateGetDetailsResp.decode(new Uint8Array(response.data));
      setCandidate(resp.candidate || undefined);
    }

    fetchCandidateDetails();
  }, []);

  return (
    <Table striped celled color={'grey'}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell colSpan='2' textAlign={'center'}>
            User Details
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <Table.Row>
          <Table.Cell width={'3'}>First Name</Table.Cell>
          <Table.Cell>{candidate?.firstName}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Last Name</Table.Cell>
          <Table.Cell>{candidate?.lastName}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Phone Number</Table.Cell>
          <Table.Cell>{candidate?.phoneNumber}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Email ID</Table.Cell>
          <Table.Cell>{candidate?.email}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Years of Experience</Table.Cell>
          <Table.Cell>{candidate?.yearsOfExperience}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Aadhar Number</Table.Cell>
          <Table.Cell>{candidate?.aadharNumber}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Date of Birth</Table.Cell>
          <Table.Cell>{candidate?.dob}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Gender</Table.Cell>
          <Table.Cell>{candidate?.gender != undefined ? proto.Enum_gender.E[candidate.gender] : ''}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Current City</Table.Cell>
          <Table.Cell>{candidate?.currentCity}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Current City Pin Code</Table.Cell>
          <Table.Cell>{candidate?.currentPinCode}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>City Born In</Table.Cell>
          <Table.Cell>{candidate?.cityBornIn}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Wiling to relocate</Table.Cell>
          <Table.Cell>{candidate?.willingToRelocate != undefined ? (candidate.willingToRelocate ? 'Yes' : 'No') : ''}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Currently Studying</Table.Cell>
          <Table.Cell>{candidate?.isCurrentlyStudying != undefined ? (candidate.isCurrentlyStudying ? 'Yes' : 'No') : ''}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Current Working</Table.Cell>
          <Table.Cell>{candidate?.isCurrentlyWorking != undefined ? (candidate.isCurrentlyWorking ? 'Yes' : 'No') : ''}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Highest Academic Qualification</Table.Cell>
          <Table.Cell>{candidate?.academicQualification != undefined ? proto.Enum_qualification.E[candidate.academicQualification] : ''}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Resume Uploaded</Table.Cell>
          <Table.Cell>
            {candidate?.resumeUploaded != undefined ? (candidate.resumeUploaded ?
              <span style={{color: '#551A8B', textDecoration: 'underline', cursor: 'pointer'}}
                    onClick={async () => {
                      const response = await makePostRequest('/public/candidate/getResumeSignedUrl', candidate?.phoneNumber, true);
                      if(response.statusCode == 200) {
                        window.open(response.data, '_blank');
                      } else {
                        console.error('Failed to open resume. Some unexpected error occurred. Response: ', response);
                        alert('Failed to open resume. Some unexpected error occurred.')
                      }
                    }}>View Resume</span>
              : 'No') : ''}
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>LinkedIn Profile Link</Table.Cell>
          <Table.Cell><a target='_blank'
                         href={candidate?.linkedinProfileLink || undefined}>{candidate?.linkedinProfileLink}</a></Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>About</Table.Cell>
          <Table.Cell>{candidate?.about}</Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  )
};
