import React, {useEffect, useState} from 'react';
import {
  Button,
  Form,
  Header,
  Icon,
  Input,
  Message,
  Segment,
} from 'semantic-ui-react'
import {proto} from '../services/source/compiled';
import {useNavigate} from 'react-router-dom';
import {makePostRequest} from '../util/api';

export const SignIn = (): JSX.Element => {
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>();
  const [otp, setOtp] = useState<string | undefined>();
  const [otpRequestedTimer, setOtpRequestedTimer] = useState(-1);
  const [isWaitingForSendOrVerifyApi, setIsWaitingForSendOrVerifyApi] = useState(false);
  const [messageState, setMessageState] = useState({hidden: true, message: '', isWarning: true});

  const navigate = useNavigate();

  const onSubmit = async (e: any) => {
    e.preventDefault();
    if (phoneNumber == undefined) {
      setMessageState({isWarning: true, hidden: false, message: 'Please enter 10 digit phone number.'});
      return;
    }
  }

  const onPhoneNumberChange = (event: any, data: any) => {
    hideMessage();
    setPhoneNumber(data.value);
  };

  const hideMessage = () => {
    if (!messageState.hidden) {
      setMessageState({hidden: true, message: '', isWarning: true});
    }
  }

  const onOTPChange = (event: any, data: any) => {
    hideMessage();
    setOtp(data.value);
  };

  useEffect(() => {
    if (otpRequestedTimer > 0) {
      setTimeout(() => {
        setOtpRequestedTimer(otpRequestedTimer - 1);
      }, 1000);
    }
  }, [otpRequestedTimer]);

  return (
    <div
      style={{
        paddingTop: '1%',
        paddingBottom: '2%',
        paddingLeft: '25%',
        paddingRight: '25%',
        background: 'lightgrey',
        minHeight: '100vh'
      }}>
      <Segment padded='very' raised>
        <Header as='h2' icon textAlign='center' block>
          <Icon name='users' circular/>
          <Header.Content>Birbal AI</Header.Content>
          <Header.Subheader>Research and development lab for ML/AI</Header.Subheader>
        </Header>
        <Form onSubmit={onSubmit} className='attached fluid segment'>
          <Header icon textAlign={'center'} size={'large'}>
            Candidate Login
          </Header>
          <Form.Field required>
            <label>Phone Number</label>
            <Input placeholder='Enter 10 digit phone number...' type={'number'} icon={'phone'} iconPosition={'left'}
                   onChange={onPhoneNumberChange} id={'phoneNumber'}/>
          </Form.Field>
          <Form.Field required>
            <label>OTP</label>
            <Input placeholder='Enter 6 digit OTP...' type={'number'} fluid icon={'list ol'} iconPosition={'left'}
                   onChange={onOTPChange} id={'phoneNumber'}/>
          </Form.Field>
          <Message negative={messageState.isWarning}
                   hidden={messageState.hidden}
                   content={messageState.message}
          />
          <Button.Group fluid>
            <Button
              disabled={phoneNumber?.length != 10 || otpRequestedTimer > 0 || isWaitingForSendOrVerifyApi}
              onClick={async () => {
                setIsWaitingForSendOrVerifyApi(true);

                setMessageState({hidden: false, message: 'Sending OTP...', isWarning: false});
                const request = new proto.PublicSendOtpReq();
                request.phoneNumber = phoneNumber || '';
                request.isUserNew = false;
                const encodedRequestBody = proto.PublicSendOtpReq.encode(request).finish();
                const res = await makePostRequest('/public/sendOtp', encodedRequestBody);
                setIsWaitingForSendOrVerifyApi(false);
                if (res.statusCode == 204) {
                  setMessageState({
                    hidden: false,
                    message: 'User not registered. Please sign up!',
                    isWarning: true
                  });
                } else if (res.statusCode != 200) {
                  setMessageState({hidden: false, message: `Failed to send OTP to ${phoneNumber}. Please try after sometime.`, isWarning: true});
                } else {
                  setMessageState({hidden: false, message: 'OTP sent!', isWarning: false});
                  setOtpRequestedTimer(60);
                }
              }}
            >Request OTP {otpRequestedTimer > 0 ? `again in ${otpRequestedTimer} sec.` : ''}</Button>
            <Button
              disabled={phoneNumber?.length != 10 || otp?.length != 6 || isWaitingForSendOrVerifyApi || otpRequestedTimer < 0}
              onClick={async () => {
                hideMessage();
                setIsWaitingForSendOrVerifyApi(true);
                const request = new proto.PublicVerifyOtpReq();
                request.phoneNumber = phoneNumber || '';
                request.OTP = otp || '';
                request.isUserNew = false;
                const encodedRequestBody = proto.PublicVerifyOtpReq.encode(request).finish();
                const response = await makePostRequest('/public/verifyOtp', encodedRequestBody, true);
                const responseMsg: string = response.data;
                if (response.statusCode == 200) {
                  if (responseMsg == 'F') {
                    setIsWaitingForSendOrVerifyApi(false);
                    setMessageState({hidden: false, message: 'Invalid OTP. Please try again!', isWarning: true});
                  } else {
                    localStorage.setItem('sessionId', responseMsg);
                    navigate('/careers-dashboard');
                  }
                } else {
                  console.error(`Failed to verify OTP. Error: ${responseMsg}`);
                  setIsWaitingForSendOrVerifyApi(false);
                  setMessageState({hidden: false, message: 'Some unexpected error occurred. Please try after sometime.', isWarning: true});
                }
              }}>Login</Button>
          </Button.Group>
        </Form>
        <Message attached='bottom'>
          <Icon name='help'/>
          Not registered?&nbsp;<a href='/sign-up'>Sign up here</a>&nbsp;instead.
        </Message>
      </Segment>
    </div>
  )
};
