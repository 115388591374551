/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.proto = (function() {

    /**
     * Namespace proto.
     * @exports proto
     * @namespace
     */
    var proto = {};

    proto.Enum_dlTestName = (function() {

        /**
         * Properties of an Enum_dlTestName.
         * @memberof proto
         * @interface IEnum_dlTestName
         */

        /**
         * Constructs a new Enum_dlTestName.
         * @memberof proto
         * @classdesc Represents an Enum_dlTestName.
         * @implements IEnum_dlTestName
         * @constructor
         * @param {proto.IEnum_dlTestName=} [properties] Properties to set
         */
        function Enum_dlTestName(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new Enum_dlTestName instance using the specified properties.
         * @function create
         * @memberof proto.Enum_dlTestName
         * @static
         * @param {proto.IEnum_dlTestName=} [properties] Properties to set
         * @returns {proto.Enum_dlTestName} Enum_dlTestName instance
         */
        Enum_dlTestName.create = function create(properties) {
            return new Enum_dlTestName(properties);
        };

        /**
         * Encodes the specified Enum_dlTestName message. Does not implicitly {@link proto.Enum_dlTestName.verify|verify} messages.
         * @function encode
         * @memberof proto.Enum_dlTestName
         * @static
         * @param {proto.IEnum_dlTestName} message Enum_dlTestName message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Enum_dlTestName.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified Enum_dlTestName message, length delimited. Does not implicitly {@link proto.Enum_dlTestName.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.Enum_dlTestName
         * @static
         * @param {proto.IEnum_dlTestName} message Enum_dlTestName message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Enum_dlTestName.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an Enum_dlTestName message from the specified reader or buffer.
         * @function decode
         * @memberof proto.Enum_dlTestName
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.Enum_dlTestName} Enum_dlTestName
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Enum_dlTestName.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.Enum_dlTestName();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an Enum_dlTestName message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.Enum_dlTestName
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.Enum_dlTestName} Enum_dlTestName
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Enum_dlTestName.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an Enum_dlTestName message.
         * @function verify
         * @memberof proto.Enum_dlTestName
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Enum_dlTestName.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates an Enum_dlTestName message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.Enum_dlTestName
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.Enum_dlTestName} Enum_dlTestName
         */
        Enum_dlTestName.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.Enum_dlTestName)
                return object;
            return new $root.proto.Enum_dlTestName();
        };

        /**
         * Creates a plain object from an Enum_dlTestName message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.Enum_dlTestName
         * @static
         * @param {proto.Enum_dlTestName} message Enum_dlTestName
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Enum_dlTestName.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this Enum_dlTestName to JSON.
         * @function toJSON
         * @memberof proto.Enum_dlTestName
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Enum_dlTestName.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * E enum.
         * @name proto.Enum_dlTestName.E
         * @enum {number}
         * @property {number} NIL=0 NIL value
         * @property {number} FILE1_TWO_MIN=1 FILE1_TWO_MIN value
         * @property {number} FILE2_SIX_MIN=2 FILE2_SIX_MIN value
         */
        Enum_dlTestName.E = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "NIL"] = 0;
            values[valuesById[1] = "FILE1_TWO_MIN"] = 1;
            values[valuesById[2] = "FILE2_SIX_MIN"] = 2;
            return values;
        })();

        return Enum_dlTestName;
    })();

    proto.Enum_dlTestStatus = (function() {

        /**
         * Properties of an Enum_dlTestStatus.
         * @memberof proto
         * @interface IEnum_dlTestStatus
         */

        /**
         * Constructs a new Enum_dlTestStatus.
         * @memberof proto
         * @classdesc Represents an Enum_dlTestStatus.
         * @implements IEnum_dlTestStatus
         * @constructor
         * @param {proto.IEnum_dlTestStatus=} [properties] Properties to set
         */
        function Enum_dlTestStatus(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new Enum_dlTestStatus instance using the specified properties.
         * @function create
         * @memberof proto.Enum_dlTestStatus
         * @static
         * @param {proto.IEnum_dlTestStatus=} [properties] Properties to set
         * @returns {proto.Enum_dlTestStatus} Enum_dlTestStatus instance
         */
        Enum_dlTestStatus.create = function create(properties) {
            return new Enum_dlTestStatus(properties);
        };

        /**
         * Encodes the specified Enum_dlTestStatus message. Does not implicitly {@link proto.Enum_dlTestStatus.verify|verify} messages.
         * @function encode
         * @memberof proto.Enum_dlTestStatus
         * @static
         * @param {proto.IEnum_dlTestStatus} message Enum_dlTestStatus message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Enum_dlTestStatus.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified Enum_dlTestStatus message, length delimited. Does not implicitly {@link proto.Enum_dlTestStatus.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.Enum_dlTestStatus
         * @static
         * @param {proto.IEnum_dlTestStatus} message Enum_dlTestStatus message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Enum_dlTestStatus.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an Enum_dlTestStatus message from the specified reader or buffer.
         * @function decode
         * @memberof proto.Enum_dlTestStatus
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.Enum_dlTestStatus} Enum_dlTestStatus
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Enum_dlTestStatus.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.Enum_dlTestStatus();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an Enum_dlTestStatus message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.Enum_dlTestStatus
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.Enum_dlTestStatus} Enum_dlTestStatus
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Enum_dlTestStatus.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an Enum_dlTestStatus message.
         * @function verify
         * @memberof proto.Enum_dlTestStatus
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Enum_dlTestStatus.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates an Enum_dlTestStatus message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.Enum_dlTestStatus
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.Enum_dlTestStatus} Enum_dlTestStatus
         */
        Enum_dlTestStatus.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.Enum_dlTestStatus)
                return object;
            return new $root.proto.Enum_dlTestStatus();
        };

        /**
         * Creates a plain object from an Enum_dlTestStatus message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.Enum_dlTestStatus
         * @static
         * @param {proto.Enum_dlTestStatus} message Enum_dlTestStatus
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Enum_dlTestStatus.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this Enum_dlTestStatus to JSON.
         * @function toJSON
         * @memberof proto.Enum_dlTestStatus
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Enum_dlTestStatus.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * E enum.
         * @name proto.Enum_dlTestStatus.E
         * @enum {number}
         * @property {number} NIL=0 NIL value
         * @property {number} NO_TEST_SCHEDULED=1 NO_TEST_SCHEDULED value
         * @property {number} FILE1_TWO_MIN_SCHEDULED=2 FILE1_TWO_MIN_SCHEDULED value
         * @property {number} FILE1_TWO_MIN_SUBMITTED=3 FILE1_TWO_MIN_SUBMITTED value
         * @property {number} FILE1_TWO_MIN_PASSED=4 FILE1_TWO_MIN_PASSED value
         * @property {number} FILE1_TWO_MIN_FAILED=5 FILE1_TWO_MIN_FAILED value
         * @property {number} FILE2_SIX_MIN_SCHEDULED=6 FILE2_SIX_MIN_SCHEDULED value
         * @property {number} FILE2_SIX_MIN_SUBMITTED=7 FILE2_SIX_MIN_SUBMITTED value
         * @property {number} FILE2_SIX_MIN_PASSED=8 FILE2_SIX_MIN_PASSED value
         * @property {number} FILE2_SIX_MIN_FAILED=9 FILE2_SIX_MIN_FAILED value
         */
        Enum_dlTestStatus.E = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "NIL"] = 0;
            values[valuesById[1] = "NO_TEST_SCHEDULED"] = 1;
            values[valuesById[2] = "FILE1_TWO_MIN_SCHEDULED"] = 2;
            values[valuesById[3] = "FILE1_TWO_MIN_SUBMITTED"] = 3;
            values[valuesById[4] = "FILE1_TWO_MIN_PASSED"] = 4;
            values[valuesById[5] = "FILE1_TWO_MIN_FAILED"] = 5;
            values[valuesById[6] = "FILE2_SIX_MIN_SCHEDULED"] = 6;
            values[valuesById[7] = "FILE2_SIX_MIN_SUBMITTED"] = 7;
            values[valuesById[8] = "FILE2_SIX_MIN_PASSED"] = 8;
            values[valuesById[9] = "FILE2_SIX_MIN_FAILED"] = 9;
            return values;
        })();

        return Enum_dlTestStatus;
    })();

    proto.Enum_gender = (function() {

        /**
         * Properties of an Enum_gender.
         * @memberof proto
         * @interface IEnum_gender
         */

        /**
         * Constructs a new Enum_gender.
         * @memberof proto
         * @classdesc Represents an Enum_gender.
         * @implements IEnum_gender
         * @constructor
         * @param {proto.IEnum_gender=} [properties] Properties to set
         */
        function Enum_gender(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new Enum_gender instance using the specified properties.
         * @function create
         * @memberof proto.Enum_gender
         * @static
         * @param {proto.IEnum_gender=} [properties] Properties to set
         * @returns {proto.Enum_gender} Enum_gender instance
         */
        Enum_gender.create = function create(properties) {
            return new Enum_gender(properties);
        };

        /**
         * Encodes the specified Enum_gender message. Does not implicitly {@link proto.Enum_gender.verify|verify} messages.
         * @function encode
         * @memberof proto.Enum_gender
         * @static
         * @param {proto.IEnum_gender} message Enum_gender message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Enum_gender.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified Enum_gender message, length delimited. Does not implicitly {@link proto.Enum_gender.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.Enum_gender
         * @static
         * @param {proto.IEnum_gender} message Enum_gender message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Enum_gender.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an Enum_gender message from the specified reader or buffer.
         * @function decode
         * @memberof proto.Enum_gender
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.Enum_gender} Enum_gender
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Enum_gender.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.Enum_gender();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an Enum_gender message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.Enum_gender
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.Enum_gender} Enum_gender
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Enum_gender.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an Enum_gender message.
         * @function verify
         * @memberof proto.Enum_gender
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Enum_gender.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates an Enum_gender message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.Enum_gender
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.Enum_gender} Enum_gender
         */
        Enum_gender.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.Enum_gender)
                return object;
            return new $root.proto.Enum_gender();
        };

        /**
         * Creates a plain object from an Enum_gender message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.Enum_gender
         * @static
         * @param {proto.Enum_gender} message Enum_gender
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Enum_gender.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this Enum_gender to JSON.
         * @function toJSON
         * @memberof proto.Enum_gender
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Enum_gender.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * E enum.
         * @name proto.Enum_gender.E
         * @enum {number}
         * @property {number} NIL=0 NIL value
         * @property {number} MALE=1 MALE value
         * @property {number} FEMALE=2 FEMALE value
         * @property {number} OTHER=3 OTHER value
         */
        Enum_gender.E = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "NIL"] = 0;
            values[valuesById[1] = "MALE"] = 1;
            values[valuesById[2] = "FEMALE"] = 2;
            values[valuesById[3] = "OTHER"] = 3;
            return values;
        })();

        return Enum_gender;
    })();

    proto.Enum_qualification = (function() {

        /**
         * Properties of an Enum_qualification.
         * @memberof proto
         * @interface IEnum_qualification
         */

        /**
         * Constructs a new Enum_qualification.
         * @memberof proto
         * @classdesc Represents an Enum_qualification.
         * @implements IEnum_qualification
         * @constructor
         * @param {proto.IEnum_qualification=} [properties] Properties to set
         */
        function Enum_qualification(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new Enum_qualification instance using the specified properties.
         * @function create
         * @memberof proto.Enum_qualification
         * @static
         * @param {proto.IEnum_qualification=} [properties] Properties to set
         * @returns {proto.Enum_qualification} Enum_qualification instance
         */
        Enum_qualification.create = function create(properties) {
            return new Enum_qualification(properties);
        };

        /**
         * Encodes the specified Enum_qualification message. Does not implicitly {@link proto.Enum_qualification.verify|verify} messages.
         * @function encode
         * @memberof proto.Enum_qualification
         * @static
         * @param {proto.IEnum_qualification} message Enum_qualification message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Enum_qualification.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified Enum_qualification message, length delimited. Does not implicitly {@link proto.Enum_qualification.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.Enum_qualification
         * @static
         * @param {proto.IEnum_qualification} message Enum_qualification message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Enum_qualification.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an Enum_qualification message from the specified reader or buffer.
         * @function decode
         * @memberof proto.Enum_qualification
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.Enum_qualification} Enum_qualification
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Enum_qualification.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.Enum_qualification();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an Enum_qualification message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.Enum_qualification
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.Enum_qualification} Enum_qualification
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Enum_qualification.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an Enum_qualification message.
         * @function verify
         * @memberof proto.Enum_qualification
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Enum_qualification.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates an Enum_qualification message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.Enum_qualification
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.Enum_qualification} Enum_qualification
         */
        Enum_qualification.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.Enum_qualification)
                return object;
            return new $root.proto.Enum_qualification();
        };

        /**
         * Creates a plain object from an Enum_qualification message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.Enum_qualification
         * @static
         * @param {proto.Enum_qualification} message Enum_qualification
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Enum_qualification.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this Enum_qualification to JSON.
         * @function toJSON
         * @memberof proto.Enum_qualification
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Enum_qualification.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * E enum.
         * @name proto.Enum_qualification.E
         * @enum {number}
         * @property {number} NIL=0 NIL value
         * @property {number} TENTH=1 TENTH value
         * @property {number} TWELFTH=2 TWELFTH value
         * @property {number} COLLEGE=3 COLLEGE value
         * @property {number} MASTERS=4 MASTERS value
         */
        Enum_qualification.E = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "NIL"] = 0;
            values[valuesById[1] = "TENTH"] = 1;
            values[valuesById[2] = "TWELFTH"] = 2;
            values[valuesById[3] = "COLLEGE"] = 3;
            values[valuesById[4] = "MASTERS"] = 4;
            return values;
        })();

        return Enum_qualification;
    })();

    proto.Candidate = (function() {

        /**
         * Properties of a Candidate.
         * @memberof proto
         * @interface ICandidate
         * @property {string|null} [firstName] Candidate firstName
         * @property {string|null} [lastName] Candidate lastName
         * @property {string|null} [phoneNumber] Candidate phoneNumber
         * @property {string|null} [email] Candidate email
         * @property {string|null} [currentCity] Candidate currentCity
         * @property {string|null} [dob] Candidate dob
         * @property {string|null} [cityBornIn] Candidate cityBornIn
         * @property {number|null} [currentPinCode] Candidate currentPinCode
         * @property {boolean|null} [willingToRelocate] Candidate willingToRelocate
         * @property {proto.Enum_qualification.E|null} [academicQualification] Candidate academicQualification
         * @property {proto.Enum_gender.E|null} [gender] Candidate gender
         * @property {number|null} [yearsOfExperience] Candidate yearsOfExperience
         * @property {string|null} [aadharNumber] Candidate aadharNumber
         * @property {boolean|null} [isCurrentlyStudying] Candidate isCurrentlyStudying
         * @property {boolean|null} [isCurrentlyWorking] Candidate isCurrentlyWorking
         * @property {boolean|null} [resumeUploaded] Candidate resumeUploaded
         * @property {string|null} [linkedinProfileLink] Candidate linkedinProfileLink
         * @property {string|null} [about] Candidate about
         */

        /**
         * Constructs a new Candidate.
         * @memberof proto
         * @classdesc Represents a Candidate.
         * @implements ICandidate
         * @constructor
         * @param {proto.ICandidate=} [properties] Properties to set
         */
        function Candidate(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Candidate firstName.
         * @member {string} firstName
         * @memberof proto.Candidate
         * @instance
         */
        Candidate.prototype.firstName = "";

        /**
         * Candidate lastName.
         * @member {string} lastName
         * @memberof proto.Candidate
         * @instance
         */
        Candidate.prototype.lastName = "";

        /**
         * Candidate phoneNumber.
         * @member {string} phoneNumber
         * @memberof proto.Candidate
         * @instance
         */
        Candidate.prototype.phoneNumber = "";

        /**
         * Candidate email.
         * @member {string} email
         * @memberof proto.Candidate
         * @instance
         */
        Candidate.prototype.email = "";

        /**
         * Candidate currentCity.
         * @member {string} currentCity
         * @memberof proto.Candidate
         * @instance
         */
        Candidate.prototype.currentCity = "";

        /**
         * Candidate dob.
         * @member {string} dob
         * @memberof proto.Candidate
         * @instance
         */
        Candidate.prototype.dob = "";

        /**
         * Candidate cityBornIn.
         * @member {string} cityBornIn
         * @memberof proto.Candidate
         * @instance
         */
        Candidate.prototype.cityBornIn = "";

        /**
         * Candidate currentPinCode.
         * @member {number} currentPinCode
         * @memberof proto.Candidate
         * @instance
         */
        Candidate.prototype.currentPinCode = 0;

        /**
         * Candidate willingToRelocate.
         * @member {boolean} willingToRelocate
         * @memberof proto.Candidate
         * @instance
         */
        Candidate.prototype.willingToRelocate = false;

        /**
         * Candidate academicQualification.
         * @member {proto.Enum_qualification.E} academicQualification
         * @memberof proto.Candidate
         * @instance
         */
        Candidate.prototype.academicQualification = 0;

        /**
         * Candidate gender.
         * @member {proto.Enum_gender.E} gender
         * @memberof proto.Candidate
         * @instance
         */
        Candidate.prototype.gender = 0;

        /**
         * Candidate yearsOfExperience.
         * @member {number} yearsOfExperience
         * @memberof proto.Candidate
         * @instance
         */
        Candidate.prototype.yearsOfExperience = 0;

        /**
         * Candidate aadharNumber.
         * @member {string} aadharNumber
         * @memberof proto.Candidate
         * @instance
         */
        Candidate.prototype.aadharNumber = "";

        /**
         * Candidate isCurrentlyStudying.
         * @member {boolean} isCurrentlyStudying
         * @memberof proto.Candidate
         * @instance
         */
        Candidate.prototype.isCurrentlyStudying = false;

        /**
         * Candidate isCurrentlyWorking.
         * @member {boolean} isCurrentlyWorking
         * @memberof proto.Candidate
         * @instance
         */
        Candidate.prototype.isCurrentlyWorking = false;

        /**
         * Candidate resumeUploaded.
         * @member {boolean} resumeUploaded
         * @memberof proto.Candidate
         * @instance
         */
        Candidate.prototype.resumeUploaded = false;

        /**
         * Candidate linkedinProfileLink.
         * @member {string} linkedinProfileLink
         * @memberof proto.Candidate
         * @instance
         */
        Candidate.prototype.linkedinProfileLink = "";

        /**
         * Candidate about.
         * @member {string} about
         * @memberof proto.Candidate
         * @instance
         */
        Candidate.prototype.about = "";

        /**
         * Creates a new Candidate instance using the specified properties.
         * @function create
         * @memberof proto.Candidate
         * @static
         * @param {proto.ICandidate=} [properties] Properties to set
         * @returns {proto.Candidate} Candidate instance
         */
        Candidate.create = function create(properties) {
            return new Candidate(properties);
        };

        /**
         * Encodes the specified Candidate message. Does not implicitly {@link proto.Candidate.verify|verify} messages.
         * @function encode
         * @memberof proto.Candidate
         * @static
         * @param {proto.ICandidate} message Candidate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Candidate.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.firstName != null && Object.hasOwnProperty.call(message, "firstName"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.firstName);
            if (message.lastName != null && Object.hasOwnProperty.call(message, "lastName"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.lastName);
            if (message.phoneNumber != null && Object.hasOwnProperty.call(message, "phoneNumber"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.phoneNumber);
            if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.email);
            if (message.currentCity != null && Object.hasOwnProperty.call(message, "currentCity"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.currentCity);
            if (message.dob != null && Object.hasOwnProperty.call(message, "dob"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.dob);
            if (message.cityBornIn != null && Object.hasOwnProperty.call(message, "cityBornIn"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.cityBornIn);
            if (message.currentPinCode != null && Object.hasOwnProperty.call(message, "currentPinCode"))
                writer.uint32(/* id 8, wireType 0 =*/64).int32(message.currentPinCode);
            if (message.willingToRelocate != null && Object.hasOwnProperty.call(message, "willingToRelocate"))
                writer.uint32(/* id 9, wireType 0 =*/72).bool(message.willingToRelocate);
            if (message.academicQualification != null && Object.hasOwnProperty.call(message, "academicQualification"))
                writer.uint32(/* id 10, wireType 0 =*/80).int32(message.academicQualification);
            if (message.gender != null && Object.hasOwnProperty.call(message, "gender"))
                writer.uint32(/* id 11, wireType 0 =*/88).int32(message.gender);
            if (message.yearsOfExperience != null && Object.hasOwnProperty.call(message, "yearsOfExperience"))
                writer.uint32(/* id 12, wireType 0 =*/96).int32(message.yearsOfExperience);
            if (message.aadharNumber != null && Object.hasOwnProperty.call(message, "aadharNumber"))
                writer.uint32(/* id 13, wireType 2 =*/106).string(message.aadharNumber);
            if (message.isCurrentlyStudying != null && Object.hasOwnProperty.call(message, "isCurrentlyStudying"))
                writer.uint32(/* id 14, wireType 0 =*/112).bool(message.isCurrentlyStudying);
            if (message.isCurrentlyWorking != null && Object.hasOwnProperty.call(message, "isCurrentlyWorking"))
                writer.uint32(/* id 15, wireType 0 =*/120).bool(message.isCurrentlyWorking);
            if (message.resumeUploaded != null && Object.hasOwnProperty.call(message, "resumeUploaded"))
                writer.uint32(/* id 16, wireType 0 =*/128).bool(message.resumeUploaded);
            if (message.linkedinProfileLink != null && Object.hasOwnProperty.call(message, "linkedinProfileLink"))
                writer.uint32(/* id 17, wireType 2 =*/138).string(message.linkedinProfileLink);
            if (message.about != null && Object.hasOwnProperty.call(message, "about"))
                writer.uint32(/* id 18, wireType 2 =*/146).string(message.about);
            return writer;
        };

        /**
         * Encodes the specified Candidate message, length delimited. Does not implicitly {@link proto.Candidate.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.Candidate
         * @static
         * @param {proto.ICandidate} message Candidate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Candidate.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Candidate message from the specified reader or buffer.
         * @function decode
         * @memberof proto.Candidate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.Candidate} Candidate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Candidate.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.Candidate();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.firstName = reader.string();
                    break;
                case 2:
                    message.lastName = reader.string();
                    break;
                case 3:
                    message.phoneNumber = reader.string();
                    break;
                case 4:
                    message.email = reader.string();
                    break;
                case 5:
                    message.currentCity = reader.string();
                    break;
                case 6:
                    message.dob = reader.string();
                    break;
                case 7:
                    message.cityBornIn = reader.string();
                    break;
                case 8:
                    message.currentPinCode = reader.int32();
                    break;
                case 9:
                    message.willingToRelocate = reader.bool();
                    break;
                case 10:
                    message.academicQualification = reader.int32();
                    break;
                case 11:
                    message.gender = reader.int32();
                    break;
                case 12:
                    message.yearsOfExperience = reader.int32();
                    break;
                case 13:
                    message.aadharNumber = reader.string();
                    break;
                case 14:
                    message.isCurrentlyStudying = reader.bool();
                    break;
                case 15:
                    message.isCurrentlyWorking = reader.bool();
                    break;
                case 16:
                    message.resumeUploaded = reader.bool();
                    break;
                case 17:
                    message.linkedinProfileLink = reader.string();
                    break;
                case 18:
                    message.about = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Candidate message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.Candidate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.Candidate} Candidate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Candidate.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Candidate message.
         * @function verify
         * @memberof proto.Candidate
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Candidate.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.firstName != null && message.hasOwnProperty("firstName"))
                if (!$util.isString(message.firstName))
                    return "firstName: string expected";
            if (message.lastName != null && message.hasOwnProperty("lastName"))
                if (!$util.isString(message.lastName))
                    return "lastName: string expected";
            if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber"))
                if (!$util.isString(message.phoneNumber))
                    return "phoneNumber: string expected";
            if (message.email != null && message.hasOwnProperty("email"))
                if (!$util.isString(message.email))
                    return "email: string expected";
            if (message.currentCity != null && message.hasOwnProperty("currentCity"))
                if (!$util.isString(message.currentCity))
                    return "currentCity: string expected";
            if (message.dob != null && message.hasOwnProperty("dob"))
                if (!$util.isString(message.dob))
                    return "dob: string expected";
            if (message.cityBornIn != null && message.hasOwnProperty("cityBornIn"))
                if (!$util.isString(message.cityBornIn))
                    return "cityBornIn: string expected";
            if (message.currentPinCode != null && message.hasOwnProperty("currentPinCode"))
                if (!$util.isInteger(message.currentPinCode))
                    return "currentPinCode: integer expected";
            if (message.willingToRelocate != null && message.hasOwnProperty("willingToRelocate"))
                if (typeof message.willingToRelocate !== "boolean")
                    return "willingToRelocate: boolean expected";
            if (message.academicQualification != null && message.hasOwnProperty("academicQualification"))
                switch (message.academicQualification) {
                default:
                    return "academicQualification: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                    break;
                }
            if (message.gender != null && message.hasOwnProperty("gender"))
                switch (message.gender) {
                default:
                    return "gender: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                    break;
                }
            if (message.yearsOfExperience != null && message.hasOwnProperty("yearsOfExperience"))
                if (!$util.isInteger(message.yearsOfExperience))
                    return "yearsOfExperience: integer expected";
            if (message.aadharNumber != null && message.hasOwnProperty("aadharNumber"))
                if (!$util.isString(message.aadharNumber))
                    return "aadharNumber: string expected";
            if (message.isCurrentlyStudying != null && message.hasOwnProperty("isCurrentlyStudying"))
                if (typeof message.isCurrentlyStudying !== "boolean")
                    return "isCurrentlyStudying: boolean expected";
            if (message.isCurrentlyWorking != null && message.hasOwnProperty("isCurrentlyWorking"))
                if (typeof message.isCurrentlyWorking !== "boolean")
                    return "isCurrentlyWorking: boolean expected";
            if (message.resumeUploaded != null && message.hasOwnProperty("resumeUploaded"))
                if (typeof message.resumeUploaded !== "boolean")
                    return "resumeUploaded: boolean expected";
            if (message.linkedinProfileLink != null && message.hasOwnProperty("linkedinProfileLink"))
                if (!$util.isString(message.linkedinProfileLink))
                    return "linkedinProfileLink: string expected";
            if (message.about != null && message.hasOwnProperty("about"))
                if (!$util.isString(message.about))
                    return "about: string expected";
            return null;
        };

        /**
         * Creates a Candidate message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.Candidate
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.Candidate} Candidate
         */
        Candidate.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.Candidate)
                return object;
            var message = new $root.proto.Candidate();
            if (object.firstName != null)
                message.firstName = String(object.firstName);
            if (object.lastName != null)
                message.lastName = String(object.lastName);
            if (object.phoneNumber != null)
                message.phoneNumber = String(object.phoneNumber);
            if (object.email != null)
                message.email = String(object.email);
            if (object.currentCity != null)
                message.currentCity = String(object.currentCity);
            if (object.dob != null)
                message.dob = String(object.dob);
            if (object.cityBornIn != null)
                message.cityBornIn = String(object.cityBornIn);
            if (object.currentPinCode != null)
                message.currentPinCode = object.currentPinCode | 0;
            if (object.willingToRelocate != null)
                message.willingToRelocate = Boolean(object.willingToRelocate);
            switch (object.academicQualification) {
            case "NIL":
            case 0:
                message.academicQualification = 0;
                break;
            case "TENTH":
            case 1:
                message.academicQualification = 1;
                break;
            case "TWELFTH":
            case 2:
                message.academicQualification = 2;
                break;
            case "COLLEGE":
            case 3:
                message.academicQualification = 3;
                break;
            case "MASTERS":
            case 4:
                message.academicQualification = 4;
                break;
            }
            switch (object.gender) {
            case "NIL":
            case 0:
                message.gender = 0;
                break;
            case "MALE":
            case 1:
                message.gender = 1;
                break;
            case "FEMALE":
            case 2:
                message.gender = 2;
                break;
            case "OTHER":
            case 3:
                message.gender = 3;
                break;
            }
            if (object.yearsOfExperience != null)
                message.yearsOfExperience = object.yearsOfExperience | 0;
            if (object.aadharNumber != null)
                message.aadharNumber = String(object.aadharNumber);
            if (object.isCurrentlyStudying != null)
                message.isCurrentlyStudying = Boolean(object.isCurrentlyStudying);
            if (object.isCurrentlyWorking != null)
                message.isCurrentlyWorking = Boolean(object.isCurrentlyWorking);
            if (object.resumeUploaded != null)
                message.resumeUploaded = Boolean(object.resumeUploaded);
            if (object.linkedinProfileLink != null)
                message.linkedinProfileLink = String(object.linkedinProfileLink);
            if (object.about != null)
                message.about = String(object.about);
            return message;
        };

        /**
         * Creates a plain object from a Candidate message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.Candidate
         * @static
         * @param {proto.Candidate} message Candidate
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Candidate.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.firstName = "";
                object.lastName = "";
                object.phoneNumber = "";
                object.email = "";
                object.currentCity = "";
                object.dob = "";
                object.cityBornIn = "";
                object.currentPinCode = 0;
                object.willingToRelocate = false;
                object.academicQualification = options.enums === String ? "NIL" : 0;
                object.gender = options.enums === String ? "NIL" : 0;
                object.yearsOfExperience = 0;
                object.aadharNumber = "";
                object.isCurrentlyStudying = false;
                object.isCurrentlyWorking = false;
                object.resumeUploaded = false;
                object.linkedinProfileLink = "";
                object.about = "";
            }
            if (message.firstName != null && message.hasOwnProperty("firstName"))
                object.firstName = message.firstName;
            if (message.lastName != null && message.hasOwnProperty("lastName"))
                object.lastName = message.lastName;
            if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber"))
                object.phoneNumber = message.phoneNumber;
            if (message.email != null && message.hasOwnProperty("email"))
                object.email = message.email;
            if (message.currentCity != null && message.hasOwnProperty("currentCity"))
                object.currentCity = message.currentCity;
            if (message.dob != null && message.hasOwnProperty("dob"))
                object.dob = message.dob;
            if (message.cityBornIn != null && message.hasOwnProperty("cityBornIn"))
                object.cityBornIn = message.cityBornIn;
            if (message.currentPinCode != null && message.hasOwnProperty("currentPinCode"))
                object.currentPinCode = message.currentPinCode;
            if (message.willingToRelocate != null && message.hasOwnProperty("willingToRelocate"))
                object.willingToRelocate = message.willingToRelocate;
            if (message.academicQualification != null && message.hasOwnProperty("academicQualification"))
                object.academicQualification = options.enums === String ? $root.proto.Enum_qualification.E[message.academicQualification] : message.academicQualification;
            if (message.gender != null && message.hasOwnProperty("gender"))
                object.gender = options.enums === String ? $root.proto.Enum_gender.E[message.gender] : message.gender;
            if (message.yearsOfExperience != null && message.hasOwnProperty("yearsOfExperience"))
                object.yearsOfExperience = message.yearsOfExperience;
            if (message.aadharNumber != null && message.hasOwnProperty("aadharNumber"))
                object.aadharNumber = message.aadharNumber;
            if (message.isCurrentlyStudying != null && message.hasOwnProperty("isCurrentlyStudying"))
                object.isCurrentlyStudying = message.isCurrentlyStudying;
            if (message.isCurrentlyWorking != null && message.hasOwnProperty("isCurrentlyWorking"))
                object.isCurrentlyWorking = message.isCurrentlyWorking;
            if (message.resumeUploaded != null && message.hasOwnProperty("resumeUploaded"))
                object.resumeUploaded = message.resumeUploaded;
            if (message.linkedinProfileLink != null && message.hasOwnProperty("linkedinProfileLink"))
                object.linkedinProfileLink = message.linkedinProfileLink;
            if (message.about != null && message.hasOwnProperty("about"))
                object.about = message.about;
            return object;
        };

        /**
         * Converts this Candidate to JSON.
         * @function toJSON
         * @memberof proto.Candidate
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Candidate.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Candidate;
    })();

    proto.DlTestSlot = (function() {

        /**
         * Properties of a DlTestSlot.
         * @memberof proto
         * @interface IDlTestSlot
         * @property {number|null} [id] DlTestSlot id
         * @property {string|null} [creatorName] DlTestSlot creatorName
         * @property {string|null} [invigilatorName] DlTestSlot invigilatorName
         * @property {string|null} [cancelledByName] DlTestSlot cancelledByName
         * @property {string|null} [cancelledTime] DlTestSlot cancelledTime
         * @property {string|null} [createdTime] DlTestSlot createdTime
         * @property {string|null} [startTime] DlTestSlot startTime
         * @property {number|null} [durationInMinutes] DlTestSlot durationInMinutes
         * @property {number|null} [maxPossibleCandidates] DlTestSlot maxPossibleCandidates
         * @property {number|null} [registeredCandidatesCount] DlTestSlot registeredCandidatesCount
         */

        /**
         * Constructs a new DlTestSlot.
         * @memberof proto
         * @classdesc Represents a DlTestSlot.
         * @implements IDlTestSlot
         * @constructor
         * @param {proto.IDlTestSlot=} [properties] Properties to set
         */
        function DlTestSlot(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DlTestSlot id.
         * @member {number} id
         * @memberof proto.DlTestSlot
         * @instance
         */
        DlTestSlot.prototype.id = 0;

        /**
         * DlTestSlot creatorName.
         * @member {string} creatorName
         * @memberof proto.DlTestSlot
         * @instance
         */
        DlTestSlot.prototype.creatorName = "";

        /**
         * DlTestSlot invigilatorName.
         * @member {string} invigilatorName
         * @memberof proto.DlTestSlot
         * @instance
         */
        DlTestSlot.prototype.invigilatorName = "";

        /**
         * DlTestSlot cancelledByName.
         * @member {string} cancelledByName
         * @memberof proto.DlTestSlot
         * @instance
         */
        DlTestSlot.prototype.cancelledByName = "";

        /**
         * DlTestSlot cancelledTime.
         * @member {string} cancelledTime
         * @memberof proto.DlTestSlot
         * @instance
         */
        DlTestSlot.prototype.cancelledTime = "";

        /**
         * DlTestSlot createdTime.
         * @member {string} createdTime
         * @memberof proto.DlTestSlot
         * @instance
         */
        DlTestSlot.prototype.createdTime = "";

        /**
         * DlTestSlot startTime.
         * @member {string} startTime
         * @memberof proto.DlTestSlot
         * @instance
         */
        DlTestSlot.prototype.startTime = "";

        /**
         * DlTestSlot durationInMinutes.
         * @member {number} durationInMinutes
         * @memberof proto.DlTestSlot
         * @instance
         */
        DlTestSlot.prototype.durationInMinutes = 0;

        /**
         * DlTestSlot maxPossibleCandidates.
         * @member {number} maxPossibleCandidates
         * @memberof proto.DlTestSlot
         * @instance
         */
        DlTestSlot.prototype.maxPossibleCandidates = 0;

        /**
         * DlTestSlot registeredCandidatesCount.
         * @member {number} registeredCandidatesCount
         * @memberof proto.DlTestSlot
         * @instance
         */
        DlTestSlot.prototype.registeredCandidatesCount = 0;

        /**
         * Creates a new DlTestSlot instance using the specified properties.
         * @function create
         * @memberof proto.DlTestSlot
         * @static
         * @param {proto.IDlTestSlot=} [properties] Properties to set
         * @returns {proto.DlTestSlot} DlTestSlot instance
         */
        DlTestSlot.create = function create(properties) {
            return new DlTestSlot(properties);
        };

        /**
         * Encodes the specified DlTestSlot message. Does not implicitly {@link proto.DlTestSlot.verify|verify} messages.
         * @function encode
         * @memberof proto.DlTestSlot
         * @static
         * @param {proto.IDlTestSlot} message DlTestSlot message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DlTestSlot.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.id);
            if (message.creatorName != null && Object.hasOwnProperty.call(message, "creatorName"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.creatorName);
            if (message.invigilatorName != null && Object.hasOwnProperty.call(message, "invigilatorName"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.invigilatorName);
            if (message.cancelledByName != null && Object.hasOwnProperty.call(message, "cancelledByName"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.cancelledByName);
            if (message.cancelledTime != null && Object.hasOwnProperty.call(message, "cancelledTime"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.cancelledTime);
            if (message.createdTime != null && Object.hasOwnProperty.call(message, "createdTime"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.createdTime);
            if (message.startTime != null && Object.hasOwnProperty.call(message, "startTime"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.startTime);
            if (message.durationInMinutes != null && Object.hasOwnProperty.call(message, "durationInMinutes"))
                writer.uint32(/* id 9, wireType 0 =*/72).int32(message.durationInMinutes);
            if (message.maxPossibleCandidates != null && Object.hasOwnProperty.call(message, "maxPossibleCandidates"))
                writer.uint32(/* id 10, wireType 0 =*/80).int32(message.maxPossibleCandidates);
            if (message.registeredCandidatesCount != null && Object.hasOwnProperty.call(message, "registeredCandidatesCount"))
                writer.uint32(/* id 11, wireType 0 =*/88).int32(message.registeredCandidatesCount);
            return writer;
        };

        /**
         * Encodes the specified DlTestSlot message, length delimited. Does not implicitly {@link proto.DlTestSlot.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.DlTestSlot
         * @static
         * @param {proto.IDlTestSlot} message DlTestSlot message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DlTestSlot.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DlTestSlot message from the specified reader or buffer.
         * @function decode
         * @memberof proto.DlTestSlot
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.DlTestSlot} DlTestSlot
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DlTestSlot.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.DlTestSlot();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.int32();
                    break;
                case 2:
                    message.creatorName = reader.string();
                    break;
                case 3:
                    message.invigilatorName = reader.string();
                    break;
                case 4:
                    message.cancelledByName = reader.string();
                    break;
                case 5:
                    message.cancelledTime = reader.string();
                    break;
                case 6:
                    message.createdTime = reader.string();
                    break;
                case 7:
                    message.startTime = reader.string();
                    break;
                case 9:
                    message.durationInMinutes = reader.int32();
                    break;
                case 10:
                    message.maxPossibleCandidates = reader.int32();
                    break;
                case 11:
                    message.registeredCandidatesCount = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DlTestSlot message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.DlTestSlot
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.DlTestSlot} DlTestSlot
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DlTestSlot.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DlTestSlot message.
         * @function verify
         * @memberof proto.DlTestSlot
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DlTestSlot.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isInteger(message.id))
                    return "id: integer expected";
            if (message.creatorName != null && message.hasOwnProperty("creatorName"))
                if (!$util.isString(message.creatorName))
                    return "creatorName: string expected";
            if (message.invigilatorName != null && message.hasOwnProperty("invigilatorName"))
                if (!$util.isString(message.invigilatorName))
                    return "invigilatorName: string expected";
            if (message.cancelledByName != null && message.hasOwnProperty("cancelledByName"))
                if (!$util.isString(message.cancelledByName))
                    return "cancelledByName: string expected";
            if (message.cancelledTime != null && message.hasOwnProperty("cancelledTime"))
                if (!$util.isString(message.cancelledTime))
                    return "cancelledTime: string expected";
            if (message.createdTime != null && message.hasOwnProperty("createdTime"))
                if (!$util.isString(message.createdTime))
                    return "createdTime: string expected";
            if (message.startTime != null && message.hasOwnProperty("startTime"))
                if (!$util.isString(message.startTime))
                    return "startTime: string expected";
            if (message.durationInMinutes != null && message.hasOwnProperty("durationInMinutes"))
                if (!$util.isInteger(message.durationInMinutes))
                    return "durationInMinutes: integer expected";
            if (message.maxPossibleCandidates != null && message.hasOwnProperty("maxPossibleCandidates"))
                if (!$util.isInteger(message.maxPossibleCandidates))
                    return "maxPossibleCandidates: integer expected";
            if (message.registeredCandidatesCount != null && message.hasOwnProperty("registeredCandidatesCount"))
                if (!$util.isInteger(message.registeredCandidatesCount))
                    return "registeredCandidatesCount: integer expected";
            return null;
        };

        /**
         * Creates a DlTestSlot message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.DlTestSlot
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.DlTestSlot} DlTestSlot
         */
        DlTestSlot.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.DlTestSlot)
                return object;
            var message = new $root.proto.DlTestSlot();
            if (object.id != null)
                message.id = object.id | 0;
            if (object.creatorName != null)
                message.creatorName = String(object.creatorName);
            if (object.invigilatorName != null)
                message.invigilatorName = String(object.invigilatorName);
            if (object.cancelledByName != null)
                message.cancelledByName = String(object.cancelledByName);
            if (object.cancelledTime != null)
                message.cancelledTime = String(object.cancelledTime);
            if (object.createdTime != null)
                message.createdTime = String(object.createdTime);
            if (object.startTime != null)
                message.startTime = String(object.startTime);
            if (object.durationInMinutes != null)
                message.durationInMinutes = object.durationInMinutes | 0;
            if (object.maxPossibleCandidates != null)
                message.maxPossibleCandidates = object.maxPossibleCandidates | 0;
            if (object.registeredCandidatesCount != null)
                message.registeredCandidatesCount = object.registeredCandidatesCount | 0;
            return message;
        };

        /**
         * Creates a plain object from a DlTestSlot message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.DlTestSlot
         * @static
         * @param {proto.DlTestSlot} message DlTestSlot
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DlTestSlot.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.id = 0;
                object.creatorName = "";
                object.invigilatorName = "";
                object.cancelledByName = "";
                object.cancelledTime = "";
                object.createdTime = "";
                object.startTime = "";
                object.durationInMinutes = 0;
                object.maxPossibleCandidates = 0;
                object.registeredCandidatesCount = 0;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.creatorName != null && message.hasOwnProperty("creatorName"))
                object.creatorName = message.creatorName;
            if (message.invigilatorName != null && message.hasOwnProperty("invigilatorName"))
                object.invigilatorName = message.invigilatorName;
            if (message.cancelledByName != null && message.hasOwnProperty("cancelledByName"))
                object.cancelledByName = message.cancelledByName;
            if (message.cancelledTime != null && message.hasOwnProperty("cancelledTime"))
                object.cancelledTime = message.cancelledTime;
            if (message.createdTime != null && message.hasOwnProperty("createdTime"))
                object.createdTime = message.createdTime;
            if (message.startTime != null && message.hasOwnProperty("startTime"))
                object.startTime = message.startTime;
            if (message.durationInMinutes != null && message.hasOwnProperty("durationInMinutes"))
                object.durationInMinutes = message.durationInMinutes;
            if (message.maxPossibleCandidates != null && message.hasOwnProperty("maxPossibleCandidates"))
                object.maxPossibleCandidates = message.maxPossibleCandidates;
            if (message.registeredCandidatesCount != null && message.hasOwnProperty("registeredCandidatesCount"))
                object.registeredCandidatesCount = message.registeredCandidatesCount;
            return object;
        };

        /**
         * Converts this DlTestSlot to JSON.
         * @function toJSON
         * @memberof proto.DlTestSlot
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DlTestSlot.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return DlTestSlot;
    })();

    proto.PublicCandidateBookTestSlotReq = (function() {

        /**
         * Properties of a PublicCandidateBookTestSlotReq.
         * @memberof proto
         * @interface IPublicCandidateBookTestSlotReq
         * @property {proto.Enum_dlTestName.E|null} [testName] PublicCandidateBookTestSlotReq testName
         * @property {number|null} [testSlotId] PublicCandidateBookTestSlotReq testSlotId
         */

        /**
         * Constructs a new PublicCandidateBookTestSlotReq.
         * @memberof proto
         * @classdesc Represents a PublicCandidateBookTestSlotReq.
         * @implements IPublicCandidateBookTestSlotReq
         * @constructor
         * @param {proto.IPublicCandidateBookTestSlotReq=} [properties] Properties to set
         */
        function PublicCandidateBookTestSlotReq(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PublicCandidateBookTestSlotReq testName.
         * @member {proto.Enum_dlTestName.E} testName
         * @memberof proto.PublicCandidateBookTestSlotReq
         * @instance
         */
        PublicCandidateBookTestSlotReq.prototype.testName = 0;

        /**
         * PublicCandidateBookTestSlotReq testSlotId.
         * @member {number} testSlotId
         * @memberof proto.PublicCandidateBookTestSlotReq
         * @instance
         */
        PublicCandidateBookTestSlotReq.prototype.testSlotId = 0;

        /**
         * Creates a new PublicCandidateBookTestSlotReq instance using the specified properties.
         * @function create
         * @memberof proto.PublicCandidateBookTestSlotReq
         * @static
         * @param {proto.IPublicCandidateBookTestSlotReq=} [properties] Properties to set
         * @returns {proto.PublicCandidateBookTestSlotReq} PublicCandidateBookTestSlotReq instance
         */
        PublicCandidateBookTestSlotReq.create = function create(properties) {
            return new PublicCandidateBookTestSlotReq(properties);
        };

        /**
         * Encodes the specified PublicCandidateBookTestSlotReq message. Does not implicitly {@link proto.PublicCandidateBookTestSlotReq.verify|verify} messages.
         * @function encode
         * @memberof proto.PublicCandidateBookTestSlotReq
         * @static
         * @param {proto.IPublicCandidateBookTestSlotReq} message PublicCandidateBookTestSlotReq message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PublicCandidateBookTestSlotReq.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.testName != null && Object.hasOwnProperty.call(message, "testName"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.testName);
            if (message.testSlotId != null && Object.hasOwnProperty.call(message, "testSlotId"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.testSlotId);
            return writer;
        };

        /**
         * Encodes the specified PublicCandidateBookTestSlotReq message, length delimited. Does not implicitly {@link proto.PublicCandidateBookTestSlotReq.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.PublicCandidateBookTestSlotReq
         * @static
         * @param {proto.IPublicCandidateBookTestSlotReq} message PublicCandidateBookTestSlotReq message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PublicCandidateBookTestSlotReq.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PublicCandidateBookTestSlotReq message from the specified reader or buffer.
         * @function decode
         * @memberof proto.PublicCandidateBookTestSlotReq
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.PublicCandidateBookTestSlotReq} PublicCandidateBookTestSlotReq
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PublicCandidateBookTestSlotReq.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.PublicCandidateBookTestSlotReq();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.testName = reader.int32();
                    break;
                case 2:
                    message.testSlotId = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PublicCandidateBookTestSlotReq message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.PublicCandidateBookTestSlotReq
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.PublicCandidateBookTestSlotReq} PublicCandidateBookTestSlotReq
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PublicCandidateBookTestSlotReq.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PublicCandidateBookTestSlotReq message.
         * @function verify
         * @memberof proto.PublicCandidateBookTestSlotReq
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PublicCandidateBookTestSlotReq.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.testName != null && message.hasOwnProperty("testName"))
                switch (message.testName) {
                default:
                    return "testName: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            if (message.testSlotId != null && message.hasOwnProperty("testSlotId"))
                if (!$util.isInteger(message.testSlotId))
                    return "testSlotId: integer expected";
            return null;
        };

        /**
         * Creates a PublicCandidateBookTestSlotReq message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.PublicCandidateBookTestSlotReq
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.PublicCandidateBookTestSlotReq} PublicCandidateBookTestSlotReq
         */
        PublicCandidateBookTestSlotReq.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.PublicCandidateBookTestSlotReq)
                return object;
            var message = new $root.proto.PublicCandidateBookTestSlotReq();
            switch (object.testName) {
            case "NIL":
            case 0:
                message.testName = 0;
                break;
            case "FILE1_TWO_MIN":
            case 1:
                message.testName = 1;
                break;
            case "FILE2_SIX_MIN":
            case 2:
                message.testName = 2;
                break;
            }
            if (object.testSlotId != null)
                message.testSlotId = object.testSlotId | 0;
            return message;
        };

        /**
         * Creates a plain object from a PublicCandidateBookTestSlotReq message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.PublicCandidateBookTestSlotReq
         * @static
         * @param {proto.PublicCandidateBookTestSlotReq} message PublicCandidateBookTestSlotReq
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PublicCandidateBookTestSlotReq.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.testName = options.enums === String ? "NIL" : 0;
                object.testSlotId = 0;
            }
            if (message.testName != null && message.hasOwnProperty("testName"))
                object.testName = options.enums === String ? $root.proto.Enum_dlTestName.E[message.testName] : message.testName;
            if (message.testSlotId != null && message.hasOwnProperty("testSlotId"))
                object.testSlotId = message.testSlotId;
            return object;
        };

        /**
         * Converts this PublicCandidateBookTestSlotReq to JSON.
         * @function toJSON
         * @memberof proto.PublicCandidateBookTestSlotReq
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PublicCandidateBookTestSlotReq.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return PublicCandidateBookTestSlotReq;
    })();

    proto.PublicCandidateGetDetailsResp = (function() {

        /**
         * Properties of a PublicCandidateGetDetailsResp.
         * @memberof proto
         * @interface IPublicCandidateGetDetailsResp
         * @property {proto.ICandidate|null} [candidate] PublicCandidateGetDetailsResp candidate
         */

        /**
         * Constructs a new PublicCandidateGetDetailsResp.
         * @memberof proto
         * @classdesc Represents a PublicCandidateGetDetailsResp.
         * @implements IPublicCandidateGetDetailsResp
         * @constructor
         * @param {proto.IPublicCandidateGetDetailsResp=} [properties] Properties to set
         */
        function PublicCandidateGetDetailsResp(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PublicCandidateGetDetailsResp candidate.
         * @member {proto.ICandidate|null|undefined} candidate
         * @memberof proto.PublicCandidateGetDetailsResp
         * @instance
         */
        PublicCandidateGetDetailsResp.prototype.candidate = null;

        /**
         * Creates a new PublicCandidateGetDetailsResp instance using the specified properties.
         * @function create
         * @memberof proto.PublicCandidateGetDetailsResp
         * @static
         * @param {proto.IPublicCandidateGetDetailsResp=} [properties] Properties to set
         * @returns {proto.PublicCandidateGetDetailsResp} PublicCandidateGetDetailsResp instance
         */
        PublicCandidateGetDetailsResp.create = function create(properties) {
            return new PublicCandidateGetDetailsResp(properties);
        };

        /**
         * Encodes the specified PublicCandidateGetDetailsResp message. Does not implicitly {@link proto.PublicCandidateGetDetailsResp.verify|verify} messages.
         * @function encode
         * @memberof proto.PublicCandidateGetDetailsResp
         * @static
         * @param {proto.IPublicCandidateGetDetailsResp} message PublicCandidateGetDetailsResp message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PublicCandidateGetDetailsResp.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.candidate != null && Object.hasOwnProperty.call(message, "candidate"))
                $root.proto.Candidate.encode(message.candidate, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified PublicCandidateGetDetailsResp message, length delimited. Does not implicitly {@link proto.PublicCandidateGetDetailsResp.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.PublicCandidateGetDetailsResp
         * @static
         * @param {proto.IPublicCandidateGetDetailsResp} message PublicCandidateGetDetailsResp message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PublicCandidateGetDetailsResp.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PublicCandidateGetDetailsResp message from the specified reader or buffer.
         * @function decode
         * @memberof proto.PublicCandidateGetDetailsResp
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.PublicCandidateGetDetailsResp} PublicCandidateGetDetailsResp
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PublicCandidateGetDetailsResp.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.PublicCandidateGetDetailsResp();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.candidate = $root.proto.Candidate.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PublicCandidateGetDetailsResp message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.PublicCandidateGetDetailsResp
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.PublicCandidateGetDetailsResp} PublicCandidateGetDetailsResp
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PublicCandidateGetDetailsResp.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PublicCandidateGetDetailsResp message.
         * @function verify
         * @memberof proto.PublicCandidateGetDetailsResp
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PublicCandidateGetDetailsResp.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.candidate != null && message.hasOwnProperty("candidate")) {
                var error = $root.proto.Candidate.verify(message.candidate);
                if (error)
                    return "candidate." + error;
            }
            return null;
        };

        /**
         * Creates a PublicCandidateGetDetailsResp message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.PublicCandidateGetDetailsResp
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.PublicCandidateGetDetailsResp} PublicCandidateGetDetailsResp
         */
        PublicCandidateGetDetailsResp.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.PublicCandidateGetDetailsResp)
                return object;
            var message = new $root.proto.PublicCandidateGetDetailsResp();
            if (object.candidate != null) {
                if (typeof object.candidate !== "object")
                    throw TypeError(".proto.PublicCandidateGetDetailsResp.candidate: object expected");
                message.candidate = $root.proto.Candidate.fromObject(object.candidate);
            }
            return message;
        };

        /**
         * Creates a plain object from a PublicCandidateGetDetailsResp message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.PublicCandidateGetDetailsResp
         * @static
         * @param {proto.PublicCandidateGetDetailsResp} message PublicCandidateGetDetailsResp
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PublicCandidateGetDetailsResp.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.candidate = null;
            if (message.candidate != null && message.hasOwnProperty("candidate"))
                object.candidate = $root.proto.Candidate.toObject(message.candidate, options);
            return object;
        };

        /**
         * Converts this PublicCandidateGetDetailsResp to JSON.
         * @function toJSON
         * @memberof proto.PublicCandidateGetDetailsResp
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PublicCandidateGetDetailsResp.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return PublicCandidateGetDetailsResp;
    })();

    proto.PublicCandidateGetSelectedSlotListResp = (function() {

        /**
         * Properties of a PublicCandidateGetSelectedSlotListResp.
         * @memberof proto
         * @interface IPublicCandidateGetSelectedSlotListResp
         * @property {Array.<proto.PublicCandidateGetSelectedSlotListResp.ISelectedSlotDetails>|null} [selectedSlotList] PublicCandidateGetSelectedSlotListResp selectedSlotList
         */

        /**
         * Constructs a new PublicCandidateGetSelectedSlotListResp.
         * @memberof proto
         * @classdesc Represents a PublicCandidateGetSelectedSlotListResp.
         * @implements IPublicCandidateGetSelectedSlotListResp
         * @constructor
         * @param {proto.IPublicCandidateGetSelectedSlotListResp=} [properties] Properties to set
         */
        function PublicCandidateGetSelectedSlotListResp(properties) {
            this.selectedSlotList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PublicCandidateGetSelectedSlotListResp selectedSlotList.
         * @member {Array.<proto.PublicCandidateGetSelectedSlotListResp.ISelectedSlotDetails>} selectedSlotList
         * @memberof proto.PublicCandidateGetSelectedSlotListResp
         * @instance
         */
        PublicCandidateGetSelectedSlotListResp.prototype.selectedSlotList = $util.emptyArray;

        /**
         * Creates a new PublicCandidateGetSelectedSlotListResp instance using the specified properties.
         * @function create
         * @memberof proto.PublicCandidateGetSelectedSlotListResp
         * @static
         * @param {proto.IPublicCandidateGetSelectedSlotListResp=} [properties] Properties to set
         * @returns {proto.PublicCandidateGetSelectedSlotListResp} PublicCandidateGetSelectedSlotListResp instance
         */
        PublicCandidateGetSelectedSlotListResp.create = function create(properties) {
            return new PublicCandidateGetSelectedSlotListResp(properties);
        };

        /**
         * Encodes the specified PublicCandidateGetSelectedSlotListResp message. Does not implicitly {@link proto.PublicCandidateGetSelectedSlotListResp.verify|verify} messages.
         * @function encode
         * @memberof proto.PublicCandidateGetSelectedSlotListResp
         * @static
         * @param {proto.IPublicCandidateGetSelectedSlotListResp} message PublicCandidateGetSelectedSlotListResp message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PublicCandidateGetSelectedSlotListResp.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.selectedSlotList != null && message.selectedSlotList.length)
                for (var i = 0; i < message.selectedSlotList.length; ++i)
                    $root.proto.PublicCandidateGetSelectedSlotListResp.SelectedSlotDetails.encode(message.selectedSlotList[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified PublicCandidateGetSelectedSlotListResp message, length delimited. Does not implicitly {@link proto.PublicCandidateGetSelectedSlotListResp.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.PublicCandidateGetSelectedSlotListResp
         * @static
         * @param {proto.IPublicCandidateGetSelectedSlotListResp} message PublicCandidateGetSelectedSlotListResp message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PublicCandidateGetSelectedSlotListResp.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PublicCandidateGetSelectedSlotListResp message from the specified reader or buffer.
         * @function decode
         * @memberof proto.PublicCandidateGetSelectedSlotListResp
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.PublicCandidateGetSelectedSlotListResp} PublicCandidateGetSelectedSlotListResp
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PublicCandidateGetSelectedSlotListResp.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.PublicCandidateGetSelectedSlotListResp();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.selectedSlotList && message.selectedSlotList.length))
                        message.selectedSlotList = [];
                    message.selectedSlotList.push($root.proto.PublicCandidateGetSelectedSlotListResp.SelectedSlotDetails.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PublicCandidateGetSelectedSlotListResp message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.PublicCandidateGetSelectedSlotListResp
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.PublicCandidateGetSelectedSlotListResp} PublicCandidateGetSelectedSlotListResp
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PublicCandidateGetSelectedSlotListResp.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PublicCandidateGetSelectedSlotListResp message.
         * @function verify
         * @memberof proto.PublicCandidateGetSelectedSlotListResp
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PublicCandidateGetSelectedSlotListResp.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.selectedSlotList != null && message.hasOwnProperty("selectedSlotList")) {
                if (!Array.isArray(message.selectedSlotList))
                    return "selectedSlotList: array expected";
                for (var i = 0; i < message.selectedSlotList.length; ++i) {
                    var error = $root.proto.PublicCandidateGetSelectedSlotListResp.SelectedSlotDetails.verify(message.selectedSlotList[i]);
                    if (error)
                        return "selectedSlotList." + error;
                }
            }
            return null;
        };

        /**
         * Creates a PublicCandidateGetSelectedSlotListResp message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.PublicCandidateGetSelectedSlotListResp
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.PublicCandidateGetSelectedSlotListResp} PublicCandidateGetSelectedSlotListResp
         */
        PublicCandidateGetSelectedSlotListResp.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.PublicCandidateGetSelectedSlotListResp)
                return object;
            var message = new $root.proto.PublicCandidateGetSelectedSlotListResp();
            if (object.selectedSlotList) {
                if (!Array.isArray(object.selectedSlotList))
                    throw TypeError(".proto.PublicCandidateGetSelectedSlotListResp.selectedSlotList: array expected");
                message.selectedSlotList = [];
                for (var i = 0; i < object.selectedSlotList.length; ++i) {
                    if (typeof object.selectedSlotList[i] !== "object")
                        throw TypeError(".proto.PublicCandidateGetSelectedSlotListResp.selectedSlotList: object expected");
                    message.selectedSlotList[i] = $root.proto.PublicCandidateGetSelectedSlotListResp.SelectedSlotDetails.fromObject(object.selectedSlotList[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a PublicCandidateGetSelectedSlotListResp message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.PublicCandidateGetSelectedSlotListResp
         * @static
         * @param {proto.PublicCandidateGetSelectedSlotListResp} message PublicCandidateGetSelectedSlotListResp
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PublicCandidateGetSelectedSlotListResp.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.selectedSlotList = [];
            if (message.selectedSlotList && message.selectedSlotList.length) {
                object.selectedSlotList = [];
                for (var j = 0; j < message.selectedSlotList.length; ++j)
                    object.selectedSlotList[j] = $root.proto.PublicCandidateGetSelectedSlotListResp.SelectedSlotDetails.toObject(message.selectedSlotList[j], options);
            }
            return object;
        };

        /**
         * Converts this PublicCandidateGetSelectedSlotListResp to JSON.
         * @function toJSON
         * @memberof proto.PublicCandidateGetSelectedSlotListResp
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PublicCandidateGetSelectedSlotListResp.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        PublicCandidateGetSelectedSlotListResp.SelectedSlotDetails = (function() {

            /**
             * Properties of a SelectedSlotDetails.
             * @memberof proto.PublicCandidateGetSelectedSlotListResp
             * @interface ISelectedSlotDetails
             * @property {number|null} [id] SelectedSlotDetails id
             * @property {proto.Enum_dlTestName.E|null} [testName] SelectedSlotDetails testName
             * @property {proto.PublicCandidateGetSelectedSlotListResp.SelectedSlotDetails.SlotStatus|null} [slotStatus] SelectedSlotDetails slotStatus
             * @property {string|null} [startTime] SelectedSlotDetails startTime
             * @property {number|null} [durationInMinutes] SelectedSlotDetails durationInMinutes
             */

            /**
             * Constructs a new SelectedSlotDetails.
             * @memberof proto.PublicCandidateGetSelectedSlotListResp
             * @classdesc Represents a SelectedSlotDetails.
             * @implements ISelectedSlotDetails
             * @constructor
             * @param {proto.PublicCandidateGetSelectedSlotListResp.ISelectedSlotDetails=} [properties] Properties to set
             */
            function SelectedSlotDetails(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SelectedSlotDetails id.
             * @member {number} id
             * @memberof proto.PublicCandidateGetSelectedSlotListResp.SelectedSlotDetails
             * @instance
             */
            SelectedSlotDetails.prototype.id = 0;

            /**
             * SelectedSlotDetails testName.
             * @member {proto.Enum_dlTestName.E} testName
             * @memberof proto.PublicCandidateGetSelectedSlotListResp.SelectedSlotDetails
             * @instance
             */
            SelectedSlotDetails.prototype.testName = 0;

            /**
             * SelectedSlotDetails slotStatus.
             * @member {proto.PublicCandidateGetSelectedSlotListResp.SelectedSlotDetails.SlotStatus} slotStatus
             * @memberof proto.PublicCandidateGetSelectedSlotListResp.SelectedSlotDetails
             * @instance
             */
            SelectedSlotDetails.prototype.slotStatus = 0;

            /**
             * SelectedSlotDetails startTime.
             * @member {string} startTime
             * @memberof proto.PublicCandidateGetSelectedSlotListResp.SelectedSlotDetails
             * @instance
             */
            SelectedSlotDetails.prototype.startTime = "";

            /**
             * SelectedSlotDetails durationInMinutes.
             * @member {number} durationInMinutes
             * @memberof proto.PublicCandidateGetSelectedSlotListResp.SelectedSlotDetails
             * @instance
             */
            SelectedSlotDetails.prototype.durationInMinutes = 0;

            /**
             * Creates a new SelectedSlotDetails instance using the specified properties.
             * @function create
             * @memberof proto.PublicCandidateGetSelectedSlotListResp.SelectedSlotDetails
             * @static
             * @param {proto.PublicCandidateGetSelectedSlotListResp.ISelectedSlotDetails=} [properties] Properties to set
             * @returns {proto.PublicCandidateGetSelectedSlotListResp.SelectedSlotDetails} SelectedSlotDetails instance
             */
            SelectedSlotDetails.create = function create(properties) {
                return new SelectedSlotDetails(properties);
            };

            /**
             * Encodes the specified SelectedSlotDetails message. Does not implicitly {@link proto.PublicCandidateGetSelectedSlotListResp.SelectedSlotDetails.verify|verify} messages.
             * @function encode
             * @memberof proto.PublicCandidateGetSelectedSlotListResp.SelectedSlotDetails
             * @static
             * @param {proto.PublicCandidateGetSelectedSlotListResp.ISelectedSlotDetails} message SelectedSlotDetails message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SelectedSlotDetails.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.id);
                if (message.testName != null && Object.hasOwnProperty.call(message, "testName"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.testName);
                if (message.slotStatus != null && Object.hasOwnProperty.call(message, "slotStatus"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.slotStatus);
                if (message.startTime != null && Object.hasOwnProperty.call(message, "startTime"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.startTime);
                if (message.durationInMinutes != null && Object.hasOwnProperty.call(message, "durationInMinutes"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int32(message.durationInMinutes);
                return writer;
            };

            /**
             * Encodes the specified SelectedSlotDetails message, length delimited. Does not implicitly {@link proto.PublicCandidateGetSelectedSlotListResp.SelectedSlotDetails.verify|verify} messages.
             * @function encodeDelimited
             * @memberof proto.PublicCandidateGetSelectedSlotListResp.SelectedSlotDetails
             * @static
             * @param {proto.PublicCandidateGetSelectedSlotListResp.ISelectedSlotDetails} message SelectedSlotDetails message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SelectedSlotDetails.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a SelectedSlotDetails message from the specified reader or buffer.
             * @function decode
             * @memberof proto.PublicCandidateGetSelectedSlotListResp.SelectedSlotDetails
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {proto.PublicCandidateGetSelectedSlotListResp.SelectedSlotDetails} SelectedSlotDetails
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SelectedSlotDetails.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.PublicCandidateGetSelectedSlotListResp.SelectedSlotDetails();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.id = reader.int32();
                        break;
                    case 2:
                        message.testName = reader.int32();
                        break;
                    case 3:
                        message.slotStatus = reader.int32();
                        break;
                    case 4:
                        message.startTime = reader.string();
                        break;
                    case 5:
                        message.durationInMinutes = reader.int32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a SelectedSlotDetails message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof proto.PublicCandidateGetSelectedSlotListResp.SelectedSlotDetails
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {proto.PublicCandidateGetSelectedSlotListResp.SelectedSlotDetails} SelectedSlotDetails
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SelectedSlotDetails.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a SelectedSlotDetails message.
             * @function verify
             * @memberof proto.PublicCandidateGetSelectedSlotListResp.SelectedSlotDetails
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SelectedSlotDetails.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id))
                        return "id: integer expected";
                if (message.testName != null && message.hasOwnProperty("testName"))
                    switch (message.testName) {
                    default:
                        return "testName: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                if (message.slotStatus != null && message.hasOwnProperty("slotStatus"))
                    switch (message.slotStatus) {
                    default:
                        return "slotStatus: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                        break;
                    }
                if (message.startTime != null && message.hasOwnProperty("startTime"))
                    if (!$util.isString(message.startTime))
                        return "startTime: string expected";
                if (message.durationInMinutes != null && message.hasOwnProperty("durationInMinutes"))
                    if (!$util.isInteger(message.durationInMinutes))
                        return "durationInMinutes: integer expected";
                return null;
            };

            /**
             * Creates a SelectedSlotDetails message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof proto.PublicCandidateGetSelectedSlotListResp.SelectedSlotDetails
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {proto.PublicCandidateGetSelectedSlotListResp.SelectedSlotDetails} SelectedSlotDetails
             */
            SelectedSlotDetails.fromObject = function fromObject(object) {
                if (object instanceof $root.proto.PublicCandidateGetSelectedSlotListResp.SelectedSlotDetails)
                    return object;
                var message = new $root.proto.PublicCandidateGetSelectedSlotListResp.SelectedSlotDetails();
                if (object.id != null)
                    message.id = object.id | 0;
                switch (object.testName) {
                case "NIL":
                case 0:
                    message.testName = 0;
                    break;
                case "FILE1_TWO_MIN":
                case 1:
                    message.testName = 1;
                    break;
                case "FILE2_SIX_MIN":
                case 2:
                    message.testName = 2;
                    break;
                }
                switch (object.slotStatus) {
                case "NIL":
                case 0:
                    message.slotStatus = 0;
                    break;
                case "CANCELLED":
                case 1:
                    message.slotStatus = 1;
                    break;
                case "SUBMITTED":
                case 2:
                    message.slotStatus = 2;
                    break;
                case "PASSED":
                case 3:
                    message.slotStatus = 3;
                    break;
                case "FAILED":
                case 4:
                    message.slotStatus = 4;
                    break;
                case "MISSED":
                case 5:
                    message.slotStatus = 5;
                    break;
                case "SCHEDULED_TODAY":
                case 6:
                    message.slotStatus = 6;
                    break;
                case "UPCOMING":
                case 7:
                    message.slotStatus = 7;
                    break;
                }
                if (object.startTime != null)
                    message.startTime = String(object.startTime);
                if (object.durationInMinutes != null)
                    message.durationInMinutes = object.durationInMinutes | 0;
                return message;
            };

            /**
             * Creates a plain object from a SelectedSlotDetails message. Also converts values to other types if specified.
             * @function toObject
             * @memberof proto.PublicCandidateGetSelectedSlotListResp.SelectedSlotDetails
             * @static
             * @param {proto.PublicCandidateGetSelectedSlotListResp.SelectedSlotDetails} message SelectedSlotDetails
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SelectedSlotDetails.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.id = 0;
                    object.testName = options.enums === String ? "NIL" : 0;
                    object.slotStatus = options.enums === String ? "NIL" : 0;
                    object.startTime = "";
                    object.durationInMinutes = 0;
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                if (message.testName != null && message.hasOwnProperty("testName"))
                    object.testName = options.enums === String ? $root.proto.Enum_dlTestName.E[message.testName] : message.testName;
                if (message.slotStatus != null && message.hasOwnProperty("slotStatus"))
                    object.slotStatus = options.enums === String ? $root.proto.PublicCandidateGetSelectedSlotListResp.SelectedSlotDetails.SlotStatus[message.slotStatus] : message.slotStatus;
                if (message.startTime != null && message.hasOwnProperty("startTime"))
                    object.startTime = message.startTime;
                if (message.durationInMinutes != null && message.hasOwnProperty("durationInMinutes"))
                    object.durationInMinutes = message.durationInMinutes;
                return object;
            };

            /**
             * Converts this SelectedSlotDetails to JSON.
             * @function toJSON
             * @memberof proto.PublicCandidateGetSelectedSlotListResp.SelectedSlotDetails
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SelectedSlotDetails.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * SlotStatus enum.
             * @name proto.PublicCandidateGetSelectedSlotListResp.SelectedSlotDetails.SlotStatus
             * @enum {number}
             * @property {number} NIL=0 NIL value
             * @property {number} CANCELLED=1 CANCELLED value
             * @property {number} SUBMITTED=2 SUBMITTED value
             * @property {number} PASSED=3 PASSED value
             * @property {number} FAILED=4 FAILED value
             * @property {number} MISSED=5 MISSED value
             * @property {number} SCHEDULED_TODAY=6 SCHEDULED_TODAY value
             * @property {number} UPCOMING=7 UPCOMING value
             */
            SelectedSlotDetails.SlotStatus = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "NIL"] = 0;
                values[valuesById[1] = "CANCELLED"] = 1;
                values[valuesById[2] = "SUBMITTED"] = 2;
                values[valuesById[3] = "PASSED"] = 3;
                values[valuesById[4] = "FAILED"] = 4;
                values[valuesById[5] = "MISSED"] = 5;
                values[valuesById[6] = "SCHEDULED_TODAY"] = 6;
                values[valuesById[7] = "UPCOMING"] = 7;
                return values;
            })();

            return SelectedSlotDetails;
        })();

        return PublicCandidateGetSelectedSlotListResp;
    })();

    proto.PublicCandidateGetTestStatusResp = (function() {

        /**
         * Properties of a PublicCandidateGetTestStatusResp.
         * @memberof proto
         * @interface IPublicCandidateGetTestStatusResp
         * @property {proto.Enum_dlTestStatus.E|null} [dlTestStatus] PublicCandidateGetTestStatusResp dlTestStatus
         */

        /**
         * Constructs a new PublicCandidateGetTestStatusResp.
         * @memberof proto
         * @classdesc Represents a PublicCandidateGetTestStatusResp.
         * @implements IPublicCandidateGetTestStatusResp
         * @constructor
         * @param {proto.IPublicCandidateGetTestStatusResp=} [properties] Properties to set
         */
        function PublicCandidateGetTestStatusResp(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PublicCandidateGetTestStatusResp dlTestStatus.
         * @member {proto.Enum_dlTestStatus.E} dlTestStatus
         * @memberof proto.PublicCandidateGetTestStatusResp
         * @instance
         */
        PublicCandidateGetTestStatusResp.prototype.dlTestStatus = 0;

        /**
         * Creates a new PublicCandidateGetTestStatusResp instance using the specified properties.
         * @function create
         * @memberof proto.PublicCandidateGetTestStatusResp
         * @static
         * @param {proto.IPublicCandidateGetTestStatusResp=} [properties] Properties to set
         * @returns {proto.PublicCandidateGetTestStatusResp} PublicCandidateGetTestStatusResp instance
         */
        PublicCandidateGetTestStatusResp.create = function create(properties) {
            return new PublicCandidateGetTestStatusResp(properties);
        };

        /**
         * Encodes the specified PublicCandidateGetTestStatusResp message. Does not implicitly {@link proto.PublicCandidateGetTestStatusResp.verify|verify} messages.
         * @function encode
         * @memberof proto.PublicCandidateGetTestStatusResp
         * @static
         * @param {proto.IPublicCandidateGetTestStatusResp} message PublicCandidateGetTestStatusResp message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PublicCandidateGetTestStatusResp.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.dlTestStatus != null && Object.hasOwnProperty.call(message, "dlTestStatus"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.dlTestStatus);
            return writer;
        };

        /**
         * Encodes the specified PublicCandidateGetTestStatusResp message, length delimited. Does not implicitly {@link proto.PublicCandidateGetTestStatusResp.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.PublicCandidateGetTestStatusResp
         * @static
         * @param {proto.IPublicCandidateGetTestStatusResp} message PublicCandidateGetTestStatusResp message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PublicCandidateGetTestStatusResp.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PublicCandidateGetTestStatusResp message from the specified reader or buffer.
         * @function decode
         * @memberof proto.PublicCandidateGetTestStatusResp
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.PublicCandidateGetTestStatusResp} PublicCandidateGetTestStatusResp
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PublicCandidateGetTestStatusResp.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.PublicCandidateGetTestStatusResp();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.dlTestStatus = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PublicCandidateGetTestStatusResp message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.PublicCandidateGetTestStatusResp
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.PublicCandidateGetTestStatusResp} PublicCandidateGetTestStatusResp
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PublicCandidateGetTestStatusResp.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PublicCandidateGetTestStatusResp message.
         * @function verify
         * @memberof proto.PublicCandidateGetTestStatusResp
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PublicCandidateGetTestStatusResp.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.dlTestStatus != null && message.hasOwnProperty("dlTestStatus"))
                switch (message.dlTestStatus) {
                default:
                    return "dlTestStatus: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                case 8:
                case 9:
                    break;
                }
            return null;
        };

        /**
         * Creates a PublicCandidateGetTestStatusResp message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.PublicCandidateGetTestStatusResp
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.PublicCandidateGetTestStatusResp} PublicCandidateGetTestStatusResp
         */
        PublicCandidateGetTestStatusResp.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.PublicCandidateGetTestStatusResp)
                return object;
            var message = new $root.proto.PublicCandidateGetTestStatusResp();
            switch (object.dlTestStatus) {
            case "NIL":
            case 0:
                message.dlTestStatus = 0;
                break;
            case "NO_TEST_SCHEDULED":
            case 1:
                message.dlTestStatus = 1;
                break;
            case "FILE1_TWO_MIN_SCHEDULED":
            case 2:
                message.dlTestStatus = 2;
                break;
            case "FILE1_TWO_MIN_SUBMITTED":
            case 3:
                message.dlTestStatus = 3;
                break;
            case "FILE1_TWO_MIN_PASSED":
            case 4:
                message.dlTestStatus = 4;
                break;
            case "FILE1_TWO_MIN_FAILED":
            case 5:
                message.dlTestStatus = 5;
                break;
            case "FILE2_SIX_MIN_SCHEDULED":
            case 6:
                message.dlTestStatus = 6;
                break;
            case "FILE2_SIX_MIN_SUBMITTED":
            case 7:
                message.dlTestStatus = 7;
                break;
            case "FILE2_SIX_MIN_PASSED":
            case 8:
                message.dlTestStatus = 8;
                break;
            case "FILE2_SIX_MIN_FAILED":
            case 9:
                message.dlTestStatus = 9;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a PublicCandidateGetTestStatusResp message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.PublicCandidateGetTestStatusResp
         * @static
         * @param {proto.PublicCandidateGetTestStatusResp} message PublicCandidateGetTestStatusResp
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PublicCandidateGetTestStatusResp.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.dlTestStatus = options.enums === String ? "NIL" : 0;
            if (message.dlTestStatus != null && message.hasOwnProperty("dlTestStatus"))
                object.dlTestStatus = options.enums === String ? $root.proto.Enum_dlTestStatus.E[message.dlTestStatus] : message.dlTestStatus;
            return object;
        };

        /**
         * Converts this PublicCandidateGetTestStatusResp to JSON.
         * @function toJSON
         * @memberof proto.PublicCandidateGetTestStatusResp
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PublicCandidateGetTestStatusResp.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return PublicCandidateGetTestStatusResp;
    })();

    proto.PublicCandidateGetUpcomingTestSlotsResp = (function() {

        /**
         * Properties of a PublicCandidateGetUpcomingTestSlotsResp.
         * @memberof proto
         * @interface IPublicCandidateGetUpcomingTestSlotsResp
         * @property {Array.<proto.IDlTestSlot>|null} [dlTestSlotList] PublicCandidateGetUpcomingTestSlotsResp dlTestSlotList
         */

        /**
         * Constructs a new PublicCandidateGetUpcomingTestSlotsResp.
         * @memberof proto
         * @classdesc Represents a PublicCandidateGetUpcomingTestSlotsResp.
         * @implements IPublicCandidateGetUpcomingTestSlotsResp
         * @constructor
         * @param {proto.IPublicCandidateGetUpcomingTestSlotsResp=} [properties] Properties to set
         */
        function PublicCandidateGetUpcomingTestSlotsResp(properties) {
            this.dlTestSlotList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PublicCandidateGetUpcomingTestSlotsResp dlTestSlotList.
         * @member {Array.<proto.IDlTestSlot>} dlTestSlotList
         * @memberof proto.PublicCandidateGetUpcomingTestSlotsResp
         * @instance
         */
        PublicCandidateGetUpcomingTestSlotsResp.prototype.dlTestSlotList = $util.emptyArray;

        /**
         * Creates a new PublicCandidateGetUpcomingTestSlotsResp instance using the specified properties.
         * @function create
         * @memberof proto.PublicCandidateGetUpcomingTestSlotsResp
         * @static
         * @param {proto.IPublicCandidateGetUpcomingTestSlotsResp=} [properties] Properties to set
         * @returns {proto.PublicCandidateGetUpcomingTestSlotsResp} PublicCandidateGetUpcomingTestSlotsResp instance
         */
        PublicCandidateGetUpcomingTestSlotsResp.create = function create(properties) {
            return new PublicCandidateGetUpcomingTestSlotsResp(properties);
        };

        /**
         * Encodes the specified PublicCandidateGetUpcomingTestSlotsResp message. Does not implicitly {@link proto.PublicCandidateGetUpcomingTestSlotsResp.verify|verify} messages.
         * @function encode
         * @memberof proto.PublicCandidateGetUpcomingTestSlotsResp
         * @static
         * @param {proto.IPublicCandidateGetUpcomingTestSlotsResp} message PublicCandidateGetUpcomingTestSlotsResp message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PublicCandidateGetUpcomingTestSlotsResp.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.dlTestSlotList != null && message.dlTestSlotList.length)
                for (var i = 0; i < message.dlTestSlotList.length; ++i)
                    $root.proto.DlTestSlot.encode(message.dlTestSlotList[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified PublicCandidateGetUpcomingTestSlotsResp message, length delimited. Does not implicitly {@link proto.PublicCandidateGetUpcomingTestSlotsResp.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.PublicCandidateGetUpcomingTestSlotsResp
         * @static
         * @param {proto.IPublicCandidateGetUpcomingTestSlotsResp} message PublicCandidateGetUpcomingTestSlotsResp message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PublicCandidateGetUpcomingTestSlotsResp.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PublicCandidateGetUpcomingTestSlotsResp message from the specified reader or buffer.
         * @function decode
         * @memberof proto.PublicCandidateGetUpcomingTestSlotsResp
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.PublicCandidateGetUpcomingTestSlotsResp} PublicCandidateGetUpcomingTestSlotsResp
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PublicCandidateGetUpcomingTestSlotsResp.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.PublicCandidateGetUpcomingTestSlotsResp();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.dlTestSlotList && message.dlTestSlotList.length))
                        message.dlTestSlotList = [];
                    message.dlTestSlotList.push($root.proto.DlTestSlot.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PublicCandidateGetUpcomingTestSlotsResp message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.PublicCandidateGetUpcomingTestSlotsResp
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.PublicCandidateGetUpcomingTestSlotsResp} PublicCandidateGetUpcomingTestSlotsResp
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PublicCandidateGetUpcomingTestSlotsResp.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PublicCandidateGetUpcomingTestSlotsResp message.
         * @function verify
         * @memberof proto.PublicCandidateGetUpcomingTestSlotsResp
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PublicCandidateGetUpcomingTestSlotsResp.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.dlTestSlotList != null && message.hasOwnProperty("dlTestSlotList")) {
                if (!Array.isArray(message.dlTestSlotList))
                    return "dlTestSlotList: array expected";
                for (var i = 0; i < message.dlTestSlotList.length; ++i) {
                    var error = $root.proto.DlTestSlot.verify(message.dlTestSlotList[i]);
                    if (error)
                        return "dlTestSlotList." + error;
                }
            }
            return null;
        };

        /**
         * Creates a PublicCandidateGetUpcomingTestSlotsResp message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.PublicCandidateGetUpcomingTestSlotsResp
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.PublicCandidateGetUpcomingTestSlotsResp} PublicCandidateGetUpcomingTestSlotsResp
         */
        PublicCandidateGetUpcomingTestSlotsResp.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.PublicCandidateGetUpcomingTestSlotsResp)
                return object;
            var message = new $root.proto.PublicCandidateGetUpcomingTestSlotsResp();
            if (object.dlTestSlotList) {
                if (!Array.isArray(object.dlTestSlotList))
                    throw TypeError(".proto.PublicCandidateGetUpcomingTestSlotsResp.dlTestSlotList: array expected");
                message.dlTestSlotList = [];
                for (var i = 0; i < object.dlTestSlotList.length; ++i) {
                    if (typeof object.dlTestSlotList[i] !== "object")
                        throw TypeError(".proto.PublicCandidateGetUpcomingTestSlotsResp.dlTestSlotList: object expected");
                    message.dlTestSlotList[i] = $root.proto.DlTestSlot.fromObject(object.dlTestSlotList[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a PublicCandidateGetUpcomingTestSlotsResp message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.PublicCandidateGetUpcomingTestSlotsResp
         * @static
         * @param {proto.PublicCandidateGetUpcomingTestSlotsResp} message PublicCandidateGetUpcomingTestSlotsResp
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PublicCandidateGetUpcomingTestSlotsResp.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.dlTestSlotList = [];
            if (message.dlTestSlotList && message.dlTestSlotList.length) {
                object.dlTestSlotList = [];
                for (var j = 0; j < message.dlTestSlotList.length; ++j)
                    object.dlTestSlotList[j] = $root.proto.DlTestSlot.toObject(message.dlTestSlotList[j], options);
            }
            return object;
        };

        /**
         * Converts this PublicCandidateGetUpcomingTestSlotsResp to JSON.
         * @function toJSON
         * @memberof proto.PublicCandidateGetUpcomingTestSlotsResp
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PublicCandidateGetUpcomingTestSlotsResp.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return PublicCandidateGetUpcomingTestSlotsResp;
    })();

    proto.PublicCreateCandidateReq = (function() {

        /**
         * Properties of a PublicCreateCandidateReq.
         * @memberof proto
         * @interface IPublicCreateCandidateReq
         * @property {proto.ICandidate|null} [candidate] PublicCreateCandidateReq candidate
         */

        /**
         * Constructs a new PublicCreateCandidateReq.
         * @memberof proto
         * @classdesc Represents a PublicCreateCandidateReq.
         * @implements IPublicCreateCandidateReq
         * @constructor
         * @param {proto.IPublicCreateCandidateReq=} [properties] Properties to set
         */
        function PublicCreateCandidateReq(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PublicCreateCandidateReq candidate.
         * @member {proto.ICandidate|null|undefined} candidate
         * @memberof proto.PublicCreateCandidateReq
         * @instance
         */
        PublicCreateCandidateReq.prototype.candidate = null;

        /**
         * Creates a new PublicCreateCandidateReq instance using the specified properties.
         * @function create
         * @memberof proto.PublicCreateCandidateReq
         * @static
         * @param {proto.IPublicCreateCandidateReq=} [properties] Properties to set
         * @returns {proto.PublicCreateCandidateReq} PublicCreateCandidateReq instance
         */
        PublicCreateCandidateReq.create = function create(properties) {
            return new PublicCreateCandidateReq(properties);
        };

        /**
         * Encodes the specified PublicCreateCandidateReq message. Does not implicitly {@link proto.PublicCreateCandidateReq.verify|verify} messages.
         * @function encode
         * @memberof proto.PublicCreateCandidateReq
         * @static
         * @param {proto.IPublicCreateCandidateReq} message PublicCreateCandidateReq message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PublicCreateCandidateReq.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.candidate != null && Object.hasOwnProperty.call(message, "candidate"))
                $root.proto.Candidate.encode(message.candidate, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified PublicCreateCandidateReq message, length delimited. Does not implicitly {@link proto.PublicCreateCandidateReq.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.PublicCreateCandidateReq
         * @static
         * @param {proto.IPublicCreateCandidateReq} message PublicCreateCandidateReq message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PublicCreateCandidateReq.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PublicCreateCandidateReq message from the specified reader or buffer.
         * @function decode
         * @memberof proto.PublicCreateCandidateReq
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.PublicCreateCandidateReq} PublicCreateCandidateReq
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PublicCreateCandidateReq.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.PublicCreateCandidateReq();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.candidate = $root.proto.Candidate.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PublicCreateCandidateReq message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.PublicCreateCandidateReq
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.PublicCreateCandidateReq} PublicCreateCandidateReq
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PublicCreateCandidateReq.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PublicCreateCandidateReq message.
         * @function verify
         * @memberof proto.PublicCreateCandidateReq
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PublicCreateCandidateReq.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.candidate != null && message.hasOwnProperty("candidate")) {
                var error = $root.proto.Candidate.verify(message.candidate);
                if (error)
                    return "candidate." + error;
            }
            return null;
        };

        /**
         * Creates a PublicCreateCandidateReq message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.PublicCreateCandidateReq
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.PublicCreateCandidateReq} PublicCreateCandidateReq
         */
        PublicCreateCandidateReq.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.PublicCreateCandidateReq)
                return object;
            var message = new $root.proto.PublicCreateCandidateReq();
            if (object.candidate != null) {
                if (typeof object.candidate !== "object")
                    throw TypeError(".proto.PublicCreateCandidateReq.candidate: object expected");
                message.candidate = $root.proto.Candidate.fromObject(object.candidate);
            }
            return message;
        };

        /**
         * Creates a plain object from a PublicCreateCandidateReq message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.PublicCreateCandidateReq
         * @static
         * @param {proto.PublicCreateCandidateReq} message PublicCreateCandidateReq
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PublicCreateCandidateReq.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.candidate = null;
            if (message.candidate != null && message.hasOwnProperty("candidate"))
                object.candidate = $root.proto.Candidate.toObject(message.candidate, options);
            return object;
        };

        /**
         * Converts this PublicCreateCandidateReq to JSON.
         * @function toJSON
         * @memberof proto.PublicCreateCandidateReq
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PublicCreateCandidateReq.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return PublicCreateCandidateReq;
    })();

    proto.PublicSendOtpReq = (function() {

        /**
         * Properties of a PublicSendOtpReq.
         * @memberof proto
         * @interface IPublicSendOtpReq
         * @property {string|null} [phoneNumber] PublicSendOtpReq phoneNumber
         * @property {boolean|null} [isUserNew] PublicSendOtpReq isUserNew
         */

        /**
         * Constructs a new PublicSendOtpReq.
         * @memberof proto
         * @classdesc Represents a PublicSendOtpReq.
         * @implements IPublicSendOtpReq
         * @constructor
         * @param {proto.IPublicSendOtpReq=} [properties] Properties to set
         */
        function PublicSendOtpReq(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PublicSendOtpReq phoneNumber.
         * @member {string} phoneNumber
         * @memberof proto.PublicSendOtpReq
         * @instance
         */
        PublicSendOtpReq.prototype.phoneNumber = "";

        /**
         * PublicSendOtpReq isUserNew.
         * @member {boolean} isUserNew
         * @memberof proto.PublicSendOtpReq
         * @instance
         */
        PublicSendOtpReq.prototype.isUserNew = false;

        /**
         * Creates a new PublicSendOtpReq instance using the specified properties.
         * @function create
         * @memberof proto.PublicSendOtpReq
         * @static
         * @param {proto.IPublicSendOtpReq=} [properties] Properties to set
         * @returns {proto.PublicSendOtpReq} PublicSendOtpReq instance
         */
        PublicSendOtpReq.create = function create(properties) {
            return new PublicSendOtpReq(properties);
        };

        /**
         * Encodes the specified PublicSendOtpReq message. Does not implicitly {@link proto.PublicSendOtpReq.verify|verify} messages.
         * @function encode
         * @memberof proto.PublicSendOtpReq
         * @static
         * @param {proto.IPublicSendOtpReq} message PublicSendOtpReq message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PublicSendOtpReq.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.phoneNumber != null && Object.hasOwnProperty.call(message, "phoneNumber"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.phoneNumber);
            if (message.isUserNew != null && Object.hasOwnProperty.call(message, "isUserNew"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.isUserNew);
            return writer;
        };

        /**
         * Encodes the specified PublicSendOtpReq message, length delimited. Does not implicitly {@link proto.PublicSendOtpReq.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.PublicSendOtpReq
         * @static
         * @param {proto.IPublicSendOtpReq} message PublicSendOtpReq message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PublicSendOtpReq.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PublicSendOtpReq message from the specified reader or buffer.
         * @function decode
         * @memberof proto.PublicSendOtpReq
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.PublicSendOtpReq} PublicSendOtpReq
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PublicSendOtpReq.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.PublicSendOtpReq();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.phoneNumber = reader.string();
                    break;
                case 2:
                    message.isUserNew = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PublicSendOtpReq message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.PublicSendOtpReq
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.PublicSendOtpReq} PublicSendOtpReq
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PublicSendOtpReq.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PublicSendOtpReq message.
         * @function verify
         * @memberof proto.PublicSendOtpReq
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PublicSendOtpReq.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber"))
                if (!$util.isString(message.phoneNumber))
                    return "phoneNumber: string expected";
            if (message.isUserNew != null && message.hasOwnProperty("isUserNew"))
                if (typeof message.isUserNew !== "boolean")
                    return "isUserNew: boolean expected";
            return null;
        };

        /**
         * Creates a PublicSendOtpReq message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.PublicSendOtpReq
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.PublicSendOtpReq} PublicSendOtpReq
         */
        PublicSendOtpReq.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.PublicSendOtpReq)
                return object;
            var message = new $root.proto.PublicSendOtpReq();
            if (object.phoneNumber != null)
                message.phoneNumber = String(object.phoneNumber);
            if (object.isUserNew != null)
                message.isUserNew = Boolean(object.isUserNew);
            return message;
        };

        /**
         * Creates a plain object from a PublicSendOtpReq message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.PublicSendOtpReq
         * @static
         * @param {proto.PublicSendOtpReq} message PublicSendOtpReq
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PublicSendOtpReq.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.phoneNumber = "";
                object.isUserNew = false;
            }
            if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber"))
                object.phoneNumber = message.phoneNumber;
            if (message.isUserNew != null && message.hasOwnProperty("isUserNew"))
                object.isUserNew = message.isUserNew;
            return object;
        };

        /**
         * Converts this PublicSendOtpReq to JSON.
         * @function toJSON
         * @memberof proto.PublicSendOtpReq
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PublicSendOtpReq.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return PublicSendOtpReq;
    })();

    proto.PublicVerifyOtpReq = (function() {

        /**
         * Properties of a PublicVerifyOtpReq.
         * @memberof proto
         * @interface IPublicVerifyOtpReq
         * @property {string|null} [phoneNumber] PublicVerifyOtpReq phoneNumber
         * @property {string|null} [OTP] PublicVerifyOtpReq OTP
         * @property {boolean|null} [isUserNew] PublicVerifyOtpReq isUserNew
         */

        /**
         * Constructs a new PublicVerifyOtpReq.
         * @memberof proto
         * @classdesc Represents a PublicVerifyOtpReq.
         * @implements IPublicVerifyOtpReq
         * @constructor
         * @param {proto.IPublicVerifyOtpReq=} [properties] Properties to set
         */
        function PublicVerifyOtpReq(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PublicVerifyOtpReq phoneNumber.
         * @member {string} phoneNumber
         * @memberof proto.PublicVerifyOtpReq
         * @instance
         */
        PublicVerifyOtpReq.prototype.phoneNumber = "";

        /**
         * PublicVerifyOtpReq OTP.
         * @member {string} OTP
         * @memberof proto.PublicVerifyOtpReq
         * @instance
         */
        PublicVerifyOtpReq.prototype.OTP = "";

        /**
         * PublicVerifyOtpReq isUserNew.
         * @member {boolean} isUserNew
         * @memberof proto.PublicVerifyOtpReq
         * @instance
         */
        PublicVerifyOtpReq.prototype.isUserNew = false;

        /**
         * Creates a new PublicVerifyOtpReq instance using the specified properties.
         * @function create
         * @memberof proto.PublicVerifyOtpReq
         * @static
         * @param {proto.IPublicVerifyOtpReq=} [properties] Properties to set
         * @returns {proto.PublicVerifyOtpReq} PublicVerifyOtpReq instance
         */
        PublicVerifyOtpReq.create = function create(properties) {
            return new PublicVerifyOtpReq(properties);
        };

        /**
         * Encodes the specified PublicVerifyOtpReq message. Does not implicitly {@link proto.PublicVerifyOtpReq.verify|verify} messages.
         * @function encode
         * @memberof proto.PublicVerifyOtpReq
         * @static
         * @param {proto.IPublicVerifyOtpReq} message PublicVerifyOtpReq message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PublicVerifyOtpReq.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.phoneNumber != null && Object.hasOwnProperty.call(message, "phoneNumber"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.phoneNumber);
            if (message.OTP != null && Object.hasOwnProperty.call(message, "OTP"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.OTP);
            if (message.isUserNew != null && Object.hasOwnProperty.call(message, "isUserNew"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.isUserNew);
            return writer;
        };

        /**
         * Encodes the specified PublicVerifyOtpReq message, length delimited. Does not implicitly {@link proto.PublicVerifyOtpReq.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.PublicVerifyOtpReq
         * @static
         * @param {proto.IPublicVerifyOtpReq} message PublicVerifyOtpReq message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PublicVerifyOtpReq.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PublicVerifyOtpReq message from the specified reader or buffer.
         * @function decode
         * @memberof proto.PublicVerifyOtpReq
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.PublicVerifyOtpReq} PublicVerifyOtpReq
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PublicVerifyOtpReq.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.PublicVerifyOtpReq();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.phoneNumber = reader.string();
                    break;
                case 2:
                    message.OTP = reader.string();
                    break;
                case 3:
                    message.isUserNew = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PublicVerifyOtpReq message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.PublicVerifyOtpReq
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.PublicVerifyOtpReq} PublicVerifyOtpReq
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PublicVerifyOtpReq.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PublicVerifyOtpReq message.
         * @function verify
         * @memberof proto.PublicVerifyOtpReq
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PublicVerifyOtpReq.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber"))
                if (!$util.isString(message.phoneNumber))
                    return "phoneNumber: string expected";
            if (message.OTP != null && message.hasOwnProperty("OTP"))
                if (!$util.isString(message.OTP))
                    return "OTP: string expected";
            if (message.isUserNew != null && message.hasOwnProperty("isUserNew"))
                if (typeof message.isUserNew !== "boolean")
                    return "isUserNew: boolean expected";
            return null;
        };

        /**
         * Creates a PublicVerifyOtpReq message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.PublicVerifyOtpReq
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.PublicVerifyOtpReq} PublicVerifyOtpReq
         */
        PublicVerifyOtpReq.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.PublicVerifyOtpReq)
                return object;
            var message = new $root.proto.PublicVerifyOtpReq();
            if (object.phoneNumber != null)
                message.phoneNumber = String(object.phoneNumber);
            if (object.OTP != null)
                message.OTP = String(object.OTP);
            if (object.isUserNew != null)
                message.isUserNew = Boolean(object.isUserNew);
            return message;
        };

        /**
         * Creates a plain object from a PublicVerifyOtpReq message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.PublicVerifyOtpReq
         * @static
         * @param {proto.PublicVerifyOtpReq} message PublicVerifyOtpReq
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PublicVerifyOtpReq.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.phoneNumber = "";
                object.OTP = "";
                object.isUserNew = false;
            }
            if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber"))
                object.phoneNumber = message.phoneNumber;
            if (message.OTP != null && message.hasOwnProperty("OTP"))
                object.OTP = message.OTP;
            if (message.isUserNew != null && message.hasOwnProperty("isUserNew"))
                object.isUserNew = message.isUserNew;
            return object;
        };

        /**
         * Converts this PublicVerifyOtpReq to JSON.
         * @function toJSON
         * @memberof proto.PublicVerifyOtpReq
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PublicVerifyOtpReq.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return PublicVerifyOtpReq;
    })();

    return proto;
})();

$root.google = (function() {

    /**
     * Namespace google.
     * @exports google
     * @namespace
     */
    var google = {};

    google.protobuf = (function() {

        /**
         * Namespace protobuf.
         * @memberof google
         * @namespace
         */
        var protobuf = {};

        protobuf.Timestamp = (function() {

            /**
             * Properties of a Timestamp.
             * @memberof google.protobuf
             * @interface ITimestamp
             * @property {number|Long|null} [seconds] Timestamp seconds
             * @property {number|null} [nanos] Timestamp nanos
             */

            /**
             * Constructs a new Timestamp.
             * @memberof google.protobuf
             * @classdesc Represents a Timestamp.
             * @implements ITimestamp
             * @constructor
             * @param {google.protobuf.ITimestamp=} [properties] Properties to set
             */
            function Timestamp(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Timestamp seconds.
             * @member {number|Long} seconds
             * @memberof google.protobuf.Timestamp
             * @instance
             */
            Timestamp.prototype.seconds = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Timestamp nanos.
             * @member {number} nanos
             * @memberof google.protobuf.Timestamp
             * @instance
             */
            Timestamp.prototype.nanos = 0;

            /**
             * Creates a new Timestamp instance using the specified properties.
             * @function create
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {google.protobuf.ITimestamp=} [properties] Properties to set
             * @returns {google.protobuf.Timestamp} Timestamp instance
             */
            Timestamp.create = function create(properties) {
                return new Timestamp(properties);
            };

            /**
             * Encodes the specified Timestamp message. Does not implicitly {@link google.protobuf.Timestamp.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {google.protobuf.ITimestamp} message Timestamp message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Timestamp.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.seconds != null && Object.hasOwnProperty.call(message, "seconds"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.seconds);
                if (message.nanos != null && Object.hasOwnProperty.call(message, "nanos"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.nanos);
                return writer;
            };

            /**
             * Encodes the specified Timestamp message, length delimited. Does not implicitly {@link google.protobuf.Timestamp.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {google.protobuf.ITimestamp} message Timestamp message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Timestamp.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Timestamp message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.Timestamp} Timestamp
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Timestamp.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.Timestamp();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.seconds = reader.int64();
                        break;
                    case 2:
                        message.nanos = reader.int32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Timestamp message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.Timestamp} Timestamp
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Timestamp.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Timestamp message.
             * @function verify
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Timestamp.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.seconds != null && message.hasOwnProperty("seconds"))
                    if (!$util.isInteger(message.seconds) && !(message.seconds && $util.isInteger(message.seconds.low) && $util.isInteger(message.seconds.high)))
                        return "seconds: integer|Long expected";
                if (message.nanos != null && message.hasOwnProperty("nanos"))
                    if (!$util.isInteger(message.nanos))
                        return "nanos: integer expected";
                return null;
            };

            /**
             * Creates a Timestamp message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.Timestamp} Timestamp
             */
            Timestamp.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.Timestamp)
                    return object;
                var message = new $root.google.protobuf.Timestamp();
                if (object.seconds != null)
                    if ($util.Long)
                        (message.seconds = $util.Long.fromValue(object.seconds)).unsigned = false;
                    else if (typeof object.seconds === "string")
                        message.seconds = parseInt(object.seconds, 10);
                    else if (typeof object.seconds === "number")
                        message.seconds = object.seconds;
                    else if (typeof object.seconds === "object")
                        message.seconds = new $util.LongBits(object.seconds.low >>> 0, object.seconds.high >>> 0).toNumber();
                if (object.nanos != null)
                    message.nanos = object.nanos | 0;
                return message;
            };

            /**
             * Creates a plain object from a Timestamp message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {google.protobuf.Timestamp} message Timestamp
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Timestamp.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.seconds = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.seconds = options.longs === String ? "0" : 0;
                    object.nanos = 0;
                }
                if (message.seconds != null && message.hasOwnProperty("seconds"))
                    if (typeof message.seconds === "number")
                        object.seconds = options.longs === String ? String(message.seconds) : message.seconds;
                    else
                        object.seconds = options.longs === String ? $util.Long.prototype.toString.call(message.seconds) : options.longs === Number ? new $util.LongBits(message.seconds.low >>> 0, message.seconds.high >>> 0).toNumber() : message.seconds;
                if (message.nanos != null && message.hasOwnProperty("nanos"))
                    object.nanos = message.nanos;
                return object;
            };

            /**
             * Converts this Timestamp to JSON.
             * @function toJSON
             * @memberof google.protobuf.Timestamp
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Timestamp.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Timestamp;
        })();

        return protobuf;
    })();

    return google;
})();

module.exports = $root;
