import React, {useEffect, useState} from 'react';
import {
  Button,
  Form,
  Header,
  Icon,
  Input,
  Message,
  Segment,
} from 'semantic-ui-react'
import {proto} from '../services/source/compiled';
import {useNavigate} from 'react-router-dom';
import {API_BASE_URL, makePostRequest} from '../util/api';
import HeaderSubHeader from 'semantic-ui-react/dist/commonjs/elements/Header/HeaderSubheader';

interface IForm {
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  email?: string;
  currentCity?: string;
  dob?: string;
  cityBornIn?: string;
  currentPinCode?: number;
  willingToRelocate?: boolean;
  academicQualification?: string;
  gender?: string;
  yearsOfExperience?: number;
  aadharNumber?: string;
  isCurrentlyStudying?: boolean;
  isCurrentlyWorking?: boolean;
  resumeUploaded: boolean;
  linkedinProfileLink?: string;
  about?: string;
  verified: boolean;
  otp?: string;
}

export const SignUp = (): JSX.Element => {
  const [candidate, setCandidate] = useState<IForm>({resumeUploaded: false, verified: false});
  const [messageState, setMessageState] = useState({hidden: true, message: '', isWarning: true});
  const [otpRequestedTimer, setOtpRequestedTimer] = useState(-1);
  const [isWaitingForSendOrVerifyApi, setIsWaitingForSendOrVerifyApi] = useState(false);
  const navigate = useNavigate();

  const genderOptions = [
    {key: 'm', text: 'Male', value: 'MALE'},
    {key: 'f', text: 'Female', value: 'FEMALE'},
    {key: 'o', text: 'Other', value: 'OTHER'},
  ];

  const qualificationOptions = [
    {key: 'TENTH', text: 'Tenth', value: 'TENTH'},
    {key: 'TWELFTH', text: 'Twelfth', value: 'TWELFTH'},
    {key: 'COLLEGE', text: 'College', value: 'COLLEGE'},
    {key: 'MASTERS', text: 'Masters', value: 'MASTERS'}
  ];

  const boolOptions = [
    {key: 'Yes', text: 'Yes', value: 'y'},
    {key: 'No', text: 'No', value: 'n'}
  ];

  const onSubmit = async (e: any) => {
    e.preventDefault();
    if (candidate.gender == undefined) {
      setMessageState({hidden: false, isWarning: true, message: 'Please select gender.'});
      return;
    }
    if (candidate.willingToRelocate == undefined) {
      setMessageState({hidden: false, isWarning: true, message: 'Please select willing to relocate.'});
      return;
    }
    if (candidate.isCurrentlyStudying == undefined) {
      setMessageState({hidden: false, isWarning: true, message: 'Please select currently studying.'});
      return;
    }
    if (candidate.isCurrentlyWorking == undefined) {
      setMessageState({hidden: false, isWarning: true, message: 'Please select currently working.'});
      return;
    }
    if (candidate.academicQualification == undefined) {
      setMessageState({hidden: false, isWarning: true, message: 'Please select academic qualification.'});
      return;
    }
    if (candidate.linkedinProfileLink != undefined && !candidate.linkedinProfileLink.startsWith('https://www.linkedin.com/')) {
      setMessageState({hidden: false, isWarning: true, message: 'Please enter valid linkedIn URL.'});
      return;
    }
    if (candidate.firstName == undefined || candidate.lastName == undefined || candidate.email == undefined || candidate.yearsOfExperience == undefined
      || candidate.aadharNumber == undefined || candidate.dob == undefined || candidate.currentCity == undefined || candidate.currentPinCode == undefined
      || candidate.cityBornIn == undefined || candidate.phoneNumber == undefined) {
      setMessageState({hidden: false, isWarning: true, message: 'Please select all fields.'});
      return;
    }

    const request = new proto.Candidate();

    request.firstName = candidate.firstName;
    request.lastName = candidate.lastName;
    request.phoneNumber = candidate.phoneNumber;
    request.email = candidate.email;
    request.currentCity = candidate.currentCity;
    request.dob = candidate.dob;
    request.firstName = candidate.firstName;
    request.cityBornIn = candidate.cityBornIn;
    request.currentPinCode = candidate.currentPinCode;
    request.willingToRelocate = candidate.willingToRelocate;
    request.academicQualification = proto.Enum_qualification.E[
      // @ts-ignore
      candidate.academicQualification];
    request.gender = proto.Enum_gender.E[
      // @ts-ignore
      candidate.gender];
    request.yearsOfExperience = candidate.yearsOfExperience;
    request.aadharNumber = candidate.aadharNumber;
    request.isCurrentlyStudying = candidate.isCurrentlyStudying;
    request.isCurrentlyWorking = candidate.isCurrentlyWorking;
    request.resumeUploaded = candidate.resumeUploaded;
    request.linkedinProfileLink = candidate.linkedinProfileLink ?? '';
    request.about = candidate.about ?? '';

    const encodedRequestBody = proto.PublicCreateCandidateReq.encode({candidate: request}).finish();
    const response = await makePostRequest('/public/createCandidate', encodedRequestBody);
    if(response.statusCode == 200) {
      alert('User successfully registered, please login.')
      navigate(`/`);
    } else {
      setMessageState({hidden: false, message: 'Failed to sign up. Please try again after sometime.', isWarning: true});
    }
  }

  const hideMessage = () => {
    if (!messageState.hidden) {
      setMessageState({hidden: true, message: '', isWarning: true});
    }
  }

  const onChange = (event: any, data: any) => {
    hideMessage();
    setCandidate(prevState => {
      const key = data.id;
      let value = data.value;

      if (key == 'currentPinCode' || key == 'yearsOfExperience') {
        value = Number(value);
      } else if (key == 'willingToRelocate' || key == 'isCurrentlyStudying' || key == 'isCurrentlyWorking') {
        value = value == 'y';
      }
      return {...prevState, [key]: value};
    })
  };

  useEffect(() => {
    if (otpRequestedTimer == -1) {
      return;
    }
    if (otpRequestedTimer > 0) {
      setTimeout(() => {
        setOtpRequestedTimer(otpRequestedTimer - 1);
      }, 1000);
    }
  }, [otpRequestedTimer]);

  const dobStart = new Date();
  const dobEnd = new Date();
  dobStart.setFullYear(dobStart.getFullYear() - 70);
  dobEnd.setFullYear(dobEnd.getFullYear() - 15);

  return (
    <div
      style={{
        paddingTop: '1%',
        paddingBottom: '2%',
        paddingLeft: '10%',
        paddingRight: '10%',
        background: 'lightgrey',
        minHeight: '100%'
      }}>
      <Segment padded='very' raised>
        <Header as='h2' icon textAlign='center' block>
          <Icon name='users' circular/>
          <Header.Content>Birbal AI</Header.Content>
          <Header.Subheader>Research and development lab for ML/AI</Header.Subheader>
        </Header>
        <Form onSubmit={onSubmit} className='attached fluid segment'>
          <Header icon textAlign={'center'} size={'large'}>
            Application for Data labeler Job
            <HeaderSubHeader content={'Please enter all the fields carefully as data once submitted cannot be edited'}/>
          </Header>
          <Form.Group inline>
            <label>Phone Number</label>
            <Form.Field required width={5}>
              <label/>
              <Input placeholder='Enter 10 digit phone number...' type={'number'}
                     onChange={onChange} id={'phoneNumber'} disabled={candidate.verified || isWaitingForSendOrVerifyApi}/>
            </Form.Field>
            {candidate.verified ? (<></>) : (
              <Button
                disabled={candidate.phoneNumber?.length != 10 || otpRequestedTimer > 0 || isWaitingForSendOrVerifyApi}
                type={'button'}
                onClick={async () => {
                  setIsWaitingForSendOrVerifyApi(true);
                  setMessageState({hidden: false, message: 'Sending OTP...', isWarning: false});
                  const request = new proto.PublicSendOtpReq();
                  request.phoneNumber = candidate.phoneNumber || '';
                  request.isUserNew = true;
                  const encodedRequestBody = proto.PublicSendOtpReq.encode(request).finish();
                  const res = await makePostRequest('/public/sendOtp', encodedRequestBody, true);
                  setIsWaitingForSendOrVerifyApi(false);
                  if (res.statusCode == 208) {
                    setMessageState({hidden: false, message: 'User already registered. Please login instead.', isWarning: true});
                  } else if (res.statusCode != 200) {
                    setMessageState({hidden: false, message: `Failed to send OTP to ${candidate.phoneNumber}. Please try again in sometime.`, isWarning: true});
                  } else {
                    setMessageState({hidden: false, message: 'OTP sent!', isWarning: false});
                    setOtpRequestedTimer(60);
                  }
                }}
              >Request OTP {otpRequestedTimer > 0 ? `again in ${otpRequestedTimer} sec.` : ''}</Button>
            )}
            {otpRequestedTimer == -1 ? (<></>) : candidate.verified ?
              (<Icon name={'checkmark'} color={'teal'} size={'large'}/>)
              : (<>
                <Form.Field required
                            width={4}>
                  <Input placeholder='Enter 6 digit OTP' type={'number'} disabled={isWaitingForSendOrVerifyApi}
                         onChange={onChange} id={'otp'} min={1e5} max={1e6}/>
                </Form.Field>
                <Button
                  disabled={candidate.phoneNumber?.length != 10 || candidate.otp?.length != 6 || isWaitingForSendOrVerifyApi}
                  type={'button'}
                  onClick={async () => {
                    hideMessage();
                    setIsWaitingForSendOrVerifyApi(true);
                    const request = new proto.PublicVerifyOtpReq();
                    request.phoneNumber = candidate.phoneNumber || '';
                    request.OTP = candidate.otp || '';
                    request.isUserNew = true;
                    const encodedRequestBody = proto.PublicVerifyOtpReq.encode(request).finish();
                    const res = await makePostRequest('/public/verifyOtp', encodedRequestBody, true);
                    const responseMsg = res.data;
                    setIsWaitingForSendOrVerifyApi(false);
                    if (res.statusCode == 200) {
                      if (responseMsg == 'T') {
                        setCandidate({...candidate, verified: true});
                      } else {
                        setMessageState({hidden: false, message: 'Invalid OTP. Please try again!', isWarning: true});
                      }
                    } else {
                      console.error(`Failed to verify OTP. Error: ${responseMsg}`);
                      alert(`Some unexpected error occurred. Please try after sometime.`);
                    }
                  }}>Verify OTP</Button>
              </>)
            }
          </Form.Group>
          <Form.Field inline>
            <Message negative={messageState.isWarning}
                     info={!messageState.isWarning}
                     hidden={messageState.hidden}
                     content={messageState.message}
            />
          </Form.Field>
          <Form.Group widths='equal'>
            <Form.Input
              id='firstName'
              required
              label='First name'
              placeholder='First name'
              onChange={onChange}
              pattern=".{2,32}"
              title="Enter 2 to 32 characters."
              disabled={!candidate.verified}
            />
            <Form.Input
              id='lastName'
              required
              label='Last name'
              placeholder='Last name'
              onChange={onChange}
              pattern='.{2,32}'
              title='Enter 2 to 32 characters.'
              disabled={!candidate.verified}
            />
          </Form.Group>
          <Form.Group widths='equal'>
            <Form.Input
              id='email'
              required
              label='Email'
              placeholder='Email ID'
              onChange={onChange}
              type={'email'}
              disabled={!candidate.verified}
            />
            <Form.Input
              id='yearsOfExperience'
              input={'number'}
              min={0}
              max={40}
              required
              label='Years of experience'
              placeholder='Years of experience'
              onChange={onChange}
              disabled={!candidate.verified}
            />
          </Form.Group>
          <Form.Group widths='equal'>
            <Form.Input
              id='aadharNumber'
              required
              label='Aadhar number'
              type={'number'}
              min={1e11}
              max={1e12 - 1}
              title="Enter 12 digits."
              placeholder='Enter 12 digit Aadhar number'
              onChange={onChange}
              disabled={!candidate.verified}
            />
            <Form.Input
              id='dob'
              required
              label='Date of Birth'
              type={'date'}
              min={`${dobStart.getFullYear()}-${String(dobStart.getMonth()).padStart(2, '0')}-${String(dobStart.getDate()).padStart(2, '0')}`}
              max={`${dobEnd.getFullYear()}-${String(dobEnd.getMonth()).padStart(2, '0')}-${String(dobEnd.getDate()).padStart(2, '0')}`}
              onChange={onChange}
              disabled={!candidate.verified}
            />
            <Form.Dropdown
              search
              required
              selection
              label='Gender'
              id='gender'
              options={genderOptions}
              onChange={onChange}
              disabled={!candidate.verified}
            />
          </Form.Group>
          <Form.Group widths='equal'>
            <Form.Input
              id='cityBornIn'
              required
              label='City born in'
              placeholder='City name'
              onChange={onChange}
              pattern='.{2,32}'
              title='Enter 2 to 32 characters.'
              disabled={!candidate.verified}
            />
            <Form.Input
              id='currentCity'
              required
              label='Current City'
              placeholder='City name'
              onChange={onChange}
              pattern='.{2,32}'
              title='Enter 2 to 32 characters.'
              disabled={!candidate.verified}
            />
            <Form.Input
              id='currentPinCode'
              required
              label='Current City Pin Code'
              placeholder='6 digit Pin Code'
              min={1e5}
              max={1e6 - 1}
              title="Enter 6 digits."
              type={'number'}
              onChange={onChange}
              disabled={!candidate.verified}
            />
            <Form.Dropdown
              search
              required
              selection
              label='Willing to relocate within Pune'
              id='willingToRelocate'
              options={boolOptions}
              onChange={onChange}
              disabled={!candidate.verified}
            />
          </Form.Group>
          <Form.Group widths='equal'>
            <Form.Dropdown
              search
              required
              selection
              label='Currently studying'
              id='isCurrentlyStudying'
              options={boolOptions}
              onChange={onChange}
              disabled={!candidate.verified}
            />
            <Form.Dropdown
              search
              required
              selection
              label='Currently working'
              id='isCurrentlyWorking'
              options={boolOptions}
              onChange={onChange}
              disabled={!candidate.verified}
            />
            <Form.Dropdown
              search
              required
              selection
              label='Highest Academic Qualification'
              id='academicQualification'
              options={qualificationOptions}
              onChange={onChange}
              disabled={!candidate.verified}
            />
          </Form.Group>
          <Form.Group widths='equal'>
            <Form.Input
              id='resume'
              label='Upload Resume in Pdf format(<5MB)'
              type={'file'}
              accept={'.pdf'}
              disabled={!candidate.verified}
              onChange={async (event, data) => {
                if(!event.target.files || event.target.files.length == 0)  return;

                if (event.target.files[0].size > 5e6) {
                  alert('Resume was not uploaded. File size should be less than 5MB.')
                  return;
                }
                if (event.target.files[0].size < 1e3) {
                  alert('Resume was not uploaded. File size should be greater than 1KB.')
                  return;
                }
                setMessageState({hidden: false, message: 'Uploading resume. Please wait....', isWarning: false});

                let formData = new FormData();
                formData.append('file', event.target.files[0], `${candidate.phoneNumber}.pdf`);
                const res = await fetch(`${API_BASE_URL}/public/uploadResume`, {method: 'POST', body: formData});
                if(res.status == 200) {
                  setCandidate({...candidate, resumeUploaded: true});
                  setMessageState({hidden: false, message: 'Resume successfully uploaded.', isWarning: false});
                } else {
                  setMessageState({hidden: false, message: 'Failed to upload resume. Please try again in some time.', isWarning: true});
                }
              }}
            />
            <Form.Input
              id='linkedinProfileLink'
              label='Linkedin Profile Link'
              placeholder='https link'
              type={'url'}
              onChange={onChange}
              disabled={!candidate.verified}
            />
          </Form.Group>
          <Form.TextArea
            id={'about'}
            label='About'
            placeholder='Tell us more about you...'
            onChange={onChange}
            pattern='.{0,4096}'
            title='Enter 0 to 4096 characters.'
            disabled={!candidate.verified}
          />
          <Form.Field inline>
            <Message info={!messageState.isWarning}
                     negative={messageState.isWarning}
                     hidden={messageState.hidden}
                     content={messageState.message}
            />
          </Form.Field>
          <Button type='submit' disabled={!candidate.verified}>Submit</Button>
        </Form>
        <Message attached='bottom' size={'large'}>
          <Icon name='help'/>
          Already signed up?&nbsp;<a href='/'>Sign In here</a>&nbsp;instead.
        </Message>
      </Segment>
    </div>
  )
};
