import React from 'react';

import {Route, Routes} from 'react-router-dom';

import {SignUp} from './components/SignUp';
import {SignIn} from "./components/SignIn";
import {CandidateMenu} from "./components/CandidateMenu";
import {Error} from "./components/Error";

export const App = (): JSX.Element => {
  return (
    <div id='App'>
      <Routes>
        <Route path={'/sign-up'} element={<SignUp/>}/>

        <Route path={'/'} element={<SignIn/>}/>

        <Route path={'/careers-dashboard'} element={<CandidateMenu/>}/>

        <Route path="*" element={<Error/>}/>
      </Routes>
    </div>
  )
}
